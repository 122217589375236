import Layout from "../../components/layout";
import React from "react";
import frame from "../../assets/images/frame-banner.svg";
import avatar1 from "../../assets/images/learing-user-01.jpg";
import avatar2 from "../../assets/images/learing-user-04.jpg";
import avatar3 from "../../assets/images/learing-user-04.jpg";

import likedinIcon from "../../assets/svg-icon/linkedin_symbol.svg";
import locationIcon from "../../assets/svg-icon/location.svg";
// learing-user-01.jpg

const metatag = {
  title: "Our Teams",
};

const ManagmentMembers = [ 
    {
        name:"Marvin McKinney",
        position:"Chief executive officer",
        location:"United Kingdom",
        photo:avatar1
    },
    {
        name:"Jenny Wilson",
        position:"Chief technical officer",
        location:"United Kingdom",
        photo:avatar2
    },
    {
        name:"Dianne Russell",
        position:"Project manager",
        location:"India",
        photo:avatar1
    }
    , 
    {
        name:"Theresa Webb",
        position:"Chief account officer",
        location:"United Kingdom",
        photo:avatar2
    }
    , 
    {
        name:"Eleanor Pena Hubert Jackson",
        position:"Business development officer",
        location:"United Kingdom",
        photo:avatar1
    }
    , 
    {
        name:"Jenny Wilson",
        position:"Chief technical officer",
        location:"United Kingdom",
        photo:avatar3
    },
    {
        name:"Dianne Russell",
        position:"Project manager",
        location:"India",
    }
];


const MemberCard = ({item}) => {
    let split = item?.name.split(" ")
    let short =""
    split.map((val,index)=>{
        if(index<2){
            short= short+val.charAt(0).toUpperCase()
        }
    })

  return (
    <div className="grid-item">
        {
            (!item.photo || item?.photo == null || item?.photo == undefined) ? <div className="photo dummy-photo"><div style={{margin:"auto"}}>{short}</div></div> : <img className="photo dummy-photo" src={item.photo} ></img>
        }
      <div className="card">
        <div className="card-contaner">
          <div className="name-container">
            <div className="name">{item.name} </div>
            <div className="linkedin-icon-container">
              <img className="linkedin-icon" src={likedinIcon} />
            </div>
          </div>
          <div className="position-location-container">
          <div className="position">{item.position}</div>
          <div className="location-container">
            <img src={locationIcon} />
            <div className="location">{item.location}</div>
          </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

const OurTeam = () => {
  return (
    <Layout page="ourTeams" metatag={metatag}>
      <article className='our-teams' id="our-teams">
        <section className="teams-cover-section" data-theme="light">
          <div className="container">
            <h1 className="title">
              Meet the People Who Make Our <br /> Workplace Thrive
            </h1>
            <p className="sub-title">
              Get to Know the Talented Individuals <br /> Who Drive Our Vision
              Forward.
            </p>
          </div>
          <img
            className="frame-bottom"
            src={frame}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            width="70%"
          />
        </section>
        <section className="teams-body-section" data-theme="light">
          <p className="sub-title">MANAGEMENT</p>
          <p className="title">
            Meet the <span>faces behind</span>
            <br /> <span className="color">Freston</span>
          </p>
          <div className="team-container">
        {
            ManagmentMembers.map((item,index)=>{
                return(<MemberCard item={item} key={index}/>)
            })
        }
          </div>
        </section>
        <section className="teams-body-section" data-theme="light">
          <p className="sub-title">MANAGEMENT</p>
          <p className="title-2">
          Meet Our <span>Dedicated</span>
            <br /> <span className="color">Team Leaders</span>
          </p>
          <div className="team-container" >

          {
            ManagmentMembers.map((item)=>{
                return(<MemberCard item={item} key={item}/>)
            })
        }

          </div>
        </section>
      </article>
    </Layout>
  );
};

export default OurTeam;
