import React,{useRef,useLayoutEffect} from 'react';
import Layout from '../../components/layout';
import LetConnect from '../../components/common/lets-coonect';
import { ButtonLink } from '../../components/ui/button'
import DispalySection from '../../components/common/service-industry-main-display'
import WordsFromPeople from '../../components/common/words-from-people';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import background from "../../assets/images/proceed/proceed-background.jpg";

const metatag={
    "title":"ProCEED"
}

const courses=[
    {
        title:"Mern Stack",
        description:"Get the confidence to build and deploy an app from scratch with Mongo DB, Express, React and Node js.",

    },
    {
        title:"Back End - Java",
        description:"Designed to help build skills and confidence to embark on a career as a Full-stack Developer",

    },
    {
        title:"Data Engineering",
        description:"A job oriented course aimed at beginners looking to forge a career in Big Data",

    },
    {
        title:"Software Testing",
        description:"A hands-on course on software testing principles and techniques to step a career in software testing",

    },
    {
        title:"React Native",
        description:"A hands-on course on software testing principles and techniques to step a career in software testing",

    },
    {
        title:"UI/UX Developer",
        description:"Enter the high demand world of UI/UX Design with practical,skill-based course in UI/UX Design",

    },
    {
        title:"One Month Training",
        description:"Familiarise yourself with current technology landscapes and popular techstacks",

    },

]

const LifeAtFreston = () => {

    const articleRef = useRef();
    const bodyRef = useRef();

    // useEffect(()=>{
    //     window.location.reload() 
    // },[])

    useLayoutEffect(() => {

    console.log("inner width",window.innerWidth)

    if(window.innerWidth > 500){

    const ctx = gsap.context(() => {
        // Target the two specific elements we have asigned the animate class
        ScrollTrigger.create({
          trigger:".sticky",
          start:"top 100px",
          end:"+=1050",//+=1650"
          markers:false,
          pin:true
        })
      }, articleRef);// <- Scope!
  
      return () => ctx.revert();

    }
      


    }, []);

    return(
        <Layout page="freston-proceed" metatag={metatag}>
            <article className='fresto-proceed' ref={bodyRef} >

                <DispalySection image={background}  title={<>Take Root and Flourish with ProSEED!</>} subtitle={<>Unlock the door to your dream job with Freston Proseed's cutting-edge courses.</>}/>

                    <section className='proceed-best-chioce-section' data-theme="light" >
                        <div className='container'>
                            <div className="row">
                                <div className="col-md-6" >
                                    <h3 className='title'>Why we are your <span>Best choice</span></h3>
                                    <div className='description'>
                                        <p>We believe that learning is a transformative experience that can unlock limitless possibilities.</p>
                                        <p>Our comprehensive courses will take you from beginner to pro, covering everything from basic concepts to advanced techniques. Whether you're looking for a career change or simply want to enhance your skills, our expert instructors are here to guide you every step of the way.</p>
                                    </div>
                                    <ButtonLink  to="https://proseed.freston.io/" target="_blank" labelcolor="black" label="KNOW MORE"/>
                                </div>
                                <div className="col-md-6">
                                    <div className='right-logo-proceed'>
                                        <div>
                                            <div>
                                                <img src="assets/images/proceed-best-choice-03.png" alt="" />
                                                <p className='logo-description' >Flexible hours and unlimited support</p>
                                            </div>
                                            <div>
                                                <img src="assets/images/proceed-best-choice-02.png" alt=""   />
                                                <p className='logo-description'>Gain the Real life Technical Skills</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <img src="assets/images/proceed-best-choice-01.png" alt=""  />
                                                <p className='logo-description'>Advanced Technologies & Framework</p>
                                            </div>
                                            <div>
                                                <img src="assets/images/proceed-best-choice-04.png" alt=""  />
                                                <p className='logo-description'>Hands-on Work Experience & Fruitful Career</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='core-values' data-theme="light">
                        <div className="container two">
                            <div className="row">
                            <div className="col-md-6" ref={articleRef}>
                                <div className="sticky">
                                <h2 className="section-title">Explore <b>Top Course Categories</b>  and Seed the Change</h2>
                                <p className="section-text ">We believe trust is earned. We are honest and transparent in all our dealings and endeavour to set realistic expectations about what can be accomplished.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {
                                    courses.map((item,index)=>{
                                        return(
                                            <div className="col-box" key={index}>
                                                <div className="head">
                                                    <h2 className="title">
                                                    <span>{item.title}</span>
                                                    </h2>
                                                </div>
                                                <h5 className="content">
                                                    {item.description}
                                                </h5>
                                                {/* <ButtonLink small labelcolor="black" label="EXPOLRE MORE"/> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            </div>
                        </div>
                    </section>

                    <section className='words-from-people-section' data-theme='light'>
                        <WordsFromPeople />
                    </section>

                <LetConnect to="/contact-us" darkicon title={<>Want to know more about ProSEED?</>} buttonlabel="LET'S CONNECT" />
                
            </article>
        </Layout>
    )
}
 
export default LifeAtFreston;