import {ButtonLink} from '../../components/ui/button'

const MainBanner = () => {
    return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="content">
            <h1 className="title">
              The Future Is Now: 5G Rollout Continues Across the Globe
            </h1>
            {/* <h5 className="date">March 30,2023</h5> */}
          </div>
          <ButtonLink to="/blogs?title=5G%20Rollout%20Continues%20Across%20the%20Globe" />
        </div>
      </div>
    </div>
     );
}
 
export default MainBanner;