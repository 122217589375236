import React, { Children } from "react";

function Breadcrumb(props) {

  //const count = Children.count(props.children);
  const childrenArray = Children.toArray(props.children);

// childrenArray.map((iiem)=>{console.log(iiem.props.title)})
// style={{ color: child.props['data-theme'] === 'light' ? 'black': 'white' }}

  return (
    <React.Fragment>
        <div className="breadcrumb-container" >        
            <div className="breadcrumb" >
                {
                    childrenArray.map((child,index)=>{

                        return(
                            <a key={index} className="breadcrumb-bar" href={`#${child.props.className}`}   >
                                <div className="breadcrumb-content">
                                    <div className="breadcrumb-title">{child.props.title}</div>
                                    <div className="breadcrumb-conttent-active"></div>
                                </div>
                            </a>
                        )

                    })
                }
            </div>
        </div>
        {
            props.children
        }
    </React.Fragment>
  );
}

export default Breadcrumb;