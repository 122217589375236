
import Layout from '../../components/layout'
import React from 'react'
import { ButtonLink } from '../../components/ui/button'
import { useNavigate,useSearchParams } from 'react-router-dom'
import frame from "../../assets/images/frame-banner.svg";
import { blogs } from '../../blogs'

// const IMAGE_BASE_URL = "http://localhost:4000/images/blogs"

import ButtonBlogBack from '../../components/ui/button'

const IMAGE_BASE_URL = "/assets/images/blogs/"

const metatag={
    "title":"Blogs"
    }

// const blogs=[
//     {
//         title:"Blockchain Technology in real world scenarios",
//         description:"Blockchain technology is one of the most talked about technologies right now. It is the underlying.",
//         author:"Jawid Musthafa",
//         date:"21, Mar 2024"
//     },
//     {
//         title:"How AI can help accelerate the advancement in Cyber Security",
//         description:"People are turning to artificial intelligence and machine learning for solutions to cybersecurity problems. That's because, in spite of constant cyberattacks, AI is continuing to improve its ability to protect network security",
//         author:"Sahal Abdul Ghafur",
//         date:"13, July 2024"
//     },
//     {
//         title:"Code Quality",
//         description:"Why Quality Code is Important: A blog around code quality and the importance of following clean coding principles.",
//         author:"Jawid Musthafa",
//         date:"21, Mar 2024"
//     },
// ]

const BlogInnerPage = () => {

    const navigate = useNavigate()
    const [ searchParams ] = useSearchParams()

    if(searchParams.get("title")!=null){

        const related_blog = blogs.filter((item)=>item.title !== searchParams.get("title"))
        const random_index = Math.floor(Math.random() * 5)
        const blog = blogs.filter((item)=>item.title === searchParams.get("title") )[0]

        return(
            <Layout page="blog" metatag={metatag} >
            <article>

                <section className='main-section-display active' data-theme='dark' style={{ background:`url(${IMAGE_BASE_URL}/${blog?.image}) center center / cover`, backgroundRepeat:'no-repeat' }} >
                    <div className='section-container' style={{backgroundColor:'rgba(0, 0, 0, 0.54)',display:'flex',width:'100%',height:'100%'}}>
                        <div className='blog-inner-page-section'>
                            {
                               searchParams.get("title") === "5G Rollout Continues Across the Globe" ?  <div className='sub-title' >{blog?.date||"date"} </div>: <div className='sub-title' >{blog?.author} <span style={{width: 6, height: 6, background: 'white', borderRadius: 9999,margin:'4px'}} /> {blog?.date||"date"} </div>
                            }
                            <h1 className='section-title'>{blog?.title||'title'}</h1>
                        </div>
                    </div>
                </section>

                <section className='blog-inner-page-blog-section' data-theme='light' >
                    <div className='section-container'>
                            <div className='inner-container' >
                            <div dangerouslySetInnerHTML={{__html: `${blog?.content||'content'}`}}/>
                            </div>
                    </div>
                </section>

                <section className='blog-inner-page-related-blog-section' data-theme='light' >

                    <div className='section-container'>

                        <div className='inner-container' >

                            <div className='head' >
                                <div className='label'> RELATED BLOGS </div> 
                                <div> 
                                    <ButtonBlogBack  to='/blogs' label="ALL BLOGS" labelcolor="black" />
                                </div>
                            </div>

                            <div className='body'>
                                {
                                related_blog.map((item,index)=>{
                                    if( index > random_index-1 && index < random_index+2 ){

                                        return(
                                
                                                <a className='item-container' href={`/blogs?title=${blogs[index].title}`} key={index} >
                                                    <img className='blog-display-pic' src={`${IMAGE_BASE_URL}/${blogs[index]?.image}`} alt=""/>
                                                    <div>
                                                        <p className='blog-author'>{ blogs[index]?.author}<span style={{width: 6, height: 6, background: '#5A536B', borderRadius: 9999,margin:'4px'}} /> {blogs[index]?.date}</p>
                                                        <p className='blog-titile'>{ blogs[index]?.author}</p>
                                                        <p className='blog-description' >{blogs[index]?.description.substring(0, 100)}...</p>
                                                        {/* <div className='explore'>EXPLORE</div> */}
                                                        <ButtonLink small to={`/blogs?title=${blogs[index].title}`} labelcolor="black" label="Explore" />
                                                    </div>
                                                </a>
                                        
                                        )
                                        
                                    }
                                })
                                }
                            </div>

                        </div>

                    </div>

                </section >

            </article>
        </Layout>
        )

    }else{
        return (
            <Layout page="carrier" metatag={metatag}>
                <article>

       <section className="teams-cover-section" data-theme="light">
          <div className="container">
            <h1 className="title">
            Uncover valuable insights that will <br /> transform your approach
            </h1>
            <p className="sub-title">
            Expand your knowledge with the help  <br /> of these informative blogs.
            </p>
          </div>
          <img
            className="frame-bottom"
            src={frame}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            width="70%"
          />
        </section>
                    
                    {/* <section className='blog-page-section' data-theme='light' >
                        <div className='container' >
                            <h1 className='title'>Uncover valuable insights that will transform your approach</h1>
                            <p className='sub-title' >Expand your knowledge with the help of these informative blogs.</p>
                        </div>
                    </section> */}
    
                    <section className='blog-recent-blog-section' data-theme='light' >
                        <div className='section-container'>
                            <div className='inner-container' >
                            <p className='head'>RECENT BLOGS</p>
                            <div className='body'>
                            <div className='body-item'>
                                <div className='item-container' onClick={()=>{navigate(`/blogs?title=${blogs[0].title}`)}}>
                                    <img className='blog-display-pic' src={`${IMAGE_BASE_URL}/${blogs[0]?.image}`} />
                                    <div >
                                    <p className='blog-author'>{ blogs[0]?.author}<span style={{width: 6, height: 6, background: '#5A536B', borderRadius: 9999,margin:'4px'}} /> {blogs[0]?.date}</p>
                                        <p className='blog-titile'>{ blogs[0].title }</p>
                                        <p className='blog-description'>{ blogs[0]?.description.substring(0, 100)}...  </p>
                                        {/* <div className='explore'>EXPLORE</div> */}
                                        <ButtonLink small to={`/blogs?title=${blogs[0].title}`} labelcolor="black" label="Explore" />
                                    </div>
                                </div>
                            </div>
                            <div className='body-item'>
                            {
                            blogs.map((item,index)=>{
                                if(index>0 && index<3){
                                    return(
                                        <div className='item-container' key={index} onClick={()=>{navigate(`/blogs/blog?title=${blogs[index].title}`)}} >
                                            <img className='blog-display-pic' src={`${IMAGE_BASE_URL}/${blogs[index]?.image}`} alt=""/>
                                            <div>
                                                <p className='blog-author'>{ blogs[index]?.author}<span style={{width: 6, height: 6, background: '#5A536B', borderRadius: 9999,margin:'4px'}} /> {blogs[index]?.date}</p>
                                                <p className='blog-titile'>{ blogs[index].title }</p>
                                                <p className='blog-description' >{blogs[index]?.description.substring(0, 100)}...</p>
                                                {/* <div className='explore'>EXPLORE</div> */}
                                                <ButtonLink small to={`/blogs?title=${blogs[index].title}`} labelcolor="black" label="Explore" />
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                            </div>
                            </div>
                            </div>
    
                        </div>
                    </section >
    
                    <section className='blog-more-blog-section'data-theme='light'  >
                    <div className='section-container'>
                    <div className='inner-container' >
    
                        <p className='head'>EXPLORE MORE BLOGS</p>
                        <div className='body'>
                            {
                            blogs.map((item,index)=>{
                                if(index>3){
    
                                    return(
                                        <div className='item-container' key={index} onClick={()=>{navigate(`/blogs?title=${blogs[index].title}`)}} >
                                            <img className='blog-display-pic' src={`${IMAGE_BASE_URL}/${blogs[index]?.image}`} alt=""/>
                                            <div>
                                                <p className='blog-author'>{ blogs[index]?.author}<span style={{width: 6, height: 6, background: '#5A536B', borderRadius: 9999,margin:'4px'}} /> {blogs[index]?.date}</p>
                                                <p className='blog-titile'>{ blogs[index].title }</p>
                                                <p className='blog-description' >{blogs[index]?.description.substring(0, 100)}...</p>
                                                {/* <div className='explore'>EXPLORE</div> */}
                                                <ButtonLink small to={`/blogs?title=${blogs[index].title}`} labelcolor="black" label="Explore" />
                                            </div>
                                        </div>
                                    )
                                    
                                }
                            })
                            }
                        </div>
                    </div>
    
    
                    {/* 
                        {
                           blogs.map((item,index)=>{
    
                            if(index > 2 && index%2 == 0){
                                return(
                                    <div className='related-blog-body' key={index}  >
                                        <div className='more-blog-item' onClick={()=>{navigate(`/blogs/blog?title=${blogs[index].title}`)}} >
                                            <img className='blog-display-pic' src={`${IMAGE_BASE_URL}/${blogs[index]?.image}`} alt=""/>
                                            <div>
                                                <p className='blog-author'> { blogs[index]?.author}  <span style={{width: 6, height: 6, background: '#5A536B', borderRadius: 9999,margin:'4px'}} /> {blogs[index]?.date} </p>
                                                <p className='blog-titile'>{blogs[index]?.title}</p>
                                                <p className='blog-description'>{blogs[index]?.description.substring(0, 100)}..</p>
                                                <div className='explore'>EXPLORE</div>
                                            </div>
                                        </div>
                                        {
                                        (blogs[index+1] !== undefined)&&(
                                        <div className='more-blog-item' onClick={()=>{navigate(`/blogs/blog?title=${blogs[index+1].title}`)}} >
                                        <img className='blog-display-pic'  src={`${IMAGE_BASE_URL}/${blogs[index+1]?.image}`} alt=""/>
                                            <div>
                                                <p className='blog-author'> {blogs[index+1]?.author}  <div style={{width: 6, height: 6, background: '#5A536B', borderRadius: 9999,margin:'4px'}} /> {blogs[index+1]?.date} </p>
                                                <p className='blog-titile'>{blogs[index+1]?.title}</p>
                                                <p className='blog-description'>{blogs[index+1]?.description.substring(0, 100)}..</p>
                                                <div className='explore'>EXPLORE</div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                )
                            }
    
                           })
                        } */
                    }
    
                    </div>
                    </section >
    
                    {/* <section className='blog-load-more-section' data-theme='light'  >
                        <div className='container' >
                            <div className='blog-load-more'>
                                <ButtonLink labelcolor="black" label="Load More"/>
                            </div>
                        </div>
                    </section> */}
    
                </article>
            </Layout>
        )
    }


}

export default BlogInnerPage