import React, { useState } from 'react';

const testimonials=[
    {
        "people-name":"Muhammed Shinan",
        "people-course":"MERN Stack @ Freston ProSEED",
        "people-image" : `assets/images/proceed/sinan.jpeg`,
        "people-words":`
        I gained a solid understanding of the MERN Stack in just a
        few months! The instructors were knowledgeable and
        patient, and the hands-on approach really helped me build
        my portfolio. Now, I'm working as a full-time developer
        and loving it! .I'm now a confident developer, Thanks to
        Freston ProSEED
      `
    },
    {
        "people-name":"Ashnad",
        "people-course":"MERN Stack @ Freston ProSEED",
        "people-image" : `assets/images/proceed/ashnad.jpeg`,
        "people-words":`
        I couldn't be happier with my experience at Freston
        ProSEED. The instructors were always available for support
        and guidance, and the community of learners made the
        process fun and engaging. I highly recommend ProSEED to
        anyone looking to break into the world of development.
      
        `,
    
    },
    {
        "people-name":"Muhammed Rasi",
        "people-course":"Data Engineering @ Freston ProSEED",
        "people-image" : `assets/images/proceed/rasi.jpeg`,
        "people-words":`
        ProSEED's Data Engineering course was a game changer for
        me. The instructors provided real-world examples that
        helped me understand complex concepts, and the hands-on
        approach allowed me to apply my knowledge in practical
        ways. I'm now working in data engineering, and it's all
        thanks to Freston ProSEED!
      
        `
    },
    {
        "people-name":"Mushraf",
        "people-course":"MERN Stack @ Freston ProSEED",
        "people-image" : `assets/images/proceed/mushraf.jpeg`,
        "people-words":`
        I came into ProSEED with little coding experience, but the
        MERN Stack course at Freston ProSEED gave me the skills I
        needed to succeed in the industry. The instructors were
        supportive and knowledgeable, and the hands-on learning
        approach made all the difference. I'm now a confident
        developer,
        `
    },
    {
        "people-name":"Ghanashyam",
        "people-course":"MERN Stack @ Freston ProSEED",
        "people-image" : `assets/images/proceed/ganashyam.jpeg`,
        "people-words":`
        Freston ProSEED's MERN Stack course transformed my career.
        The instructors were engaging, and the curriculum was
        comprehensive, covering everything I needed to know. I'm
        now working as a developer, and I owe it all to the
        fantastic education I received at ProSEED.
        `
    },
    {
        "people-name":"Vishnu",
        "people-course":"MERN Stack @ Freston ProSEED",
        "people-image" : `assets/images/proceed/vishnu.jpeg`,
        "people-words":`
        The MERN Stack course at Freston ProSEED was the perfect
        stepping stone for my career in development. The
        instructors were passionate about teaching and genuinely
        cared about my success. I'm proud to be a part of the
        ProSEED community and look forward to further growth in my
        career.
        `
    },
    {
        "people-name":"Jyothis",
        "people-course":"MERN Stack @ Freston ProSEED",
        "people-image" : `assets/images/proceed/jyothis.jpeg`,
        "people-words":`
        MERN Stack "From the start, I knew that Freston ProSEED
        was the right choice for my development education. The
        MERN Stack course was comprehensive and engaging, and the
        instructors provided valuable insights and guidance.
        Thanks to ProSEED, I'm now working as a successful
        developer.
        `
    },
    {
        "people-name":"Christina",
        "people-course":"MERN Stack @ Freston ProSEED",
        "people-image" : `assets/images/proceed/chiristina.jpeg`,
        "people-words":`
        I had always been interested in software Testing, but I
        didn't know where to start. Freston ProSEED's Testing
        course provided me with the foundation I needed to excel
        in the industry. The instructors were patient and
        knowledgeable, and the hands-on learning approach made all
        the difference. I can't thank ProSEED enough for helping
        me achieve my goals!
        `
    },
]

const WordsFromPeople = ( props ) => {

    const [index,setIndex]=useState(0);
    const [direction ,setDirection] =useState('right')
    
    return (
            <div className='word-from-people container'>

                <h3 className='title'> It's all about people <span> lets hear them out </span></h3>

                <div className='large'>
                    <div className='row' >
                        <div className='col-md-8 first' >
                            <div className='navigation-container'>
                                    <img className='navigation-arrow' src='assets/images/left-arrow.png' onClick={()=>{setIndex( index === 0 ? 2 :  index-1 );setDirection('left')}} alt=""></img>
                                    <img className='navigation-arrow' src='assets/images/right-arrow.png' onClick={()=>{setIndex( index === 2 ? 0 :  index+1 );setDirection('right')}}  alt=""></img>
                            </div>
                            <div className={`body animated ${ direction === 'right'? "fadeInRight" : "fadeInLeft" }`} key={index}>
                                <img className='quotes' src='assets/images/quotes.png' alt="" />
                                <div className={`people`}>
                                    <p className='people-words'>
                                        {testimonials[index]['people-words']}
                                    </p>
                                    <div className={`people-name animated ${ direction === 'right'? "fadeInRight" : "fadeInLeft" }`} key={index}>
                                        {testimonials[index]['people-name']}
                                        <br />
                                        <span>{testimonials[index]['people-course']}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-4 second animated ${ direction === 'right'? "fadeInRight" : "fadeInLeft" }`} key={index} >
                            <img className='people-img' src={`${testimonials[index]['people-image']}`} alt="" />
                        </div>
                    </div>
                </div>

                <div className='mobile'>

                    <div className='inner-card' >
                            <div className='icon-container'>
                                <img className="icon" height={55} width={55} src="assets/images/testimonial-icon.png" alt=""/> 
                            </div>
                            <div className='inner-card-body'>
                                <p className='inner-card-words'>"Freston Analytics retains its edge in the fast evolving  has technology sector"</p>
                                <h3 className='inner-card-author'>
                                   <p>Adarsh Lohithakshan</p> 
                                    <p className='span'>Frontend Developer</p>
                                </h3>
                            </div>
                    </div>

                    <div className='inner-card' >
                            <div className='icon-container'>
                                <img className="icon" height={55} width={55} src="assets/images/testimonial-icon.png" alt=""/> 
                            </div>
                            <div className='inner-card-body'>
                                <p className='inner-card-words'>"Freston Analytics retains its edge in the fast evolving  has technology sector"</p>
                                <h3 className='inner-card-author'>
                                   <p>Adarsh Lohithakshan</p> 
                                    <p className='span'>Frontend Developer</p>
                                </h3>
                            </div>
                    </div>

                </div>

            </div>
    );
}

export default WordsFromPeople;