import React ,{ useEffect ,useState }from "react"
import { Link } from 'react-router-dom'
import { ButtonConnect } from '../ui/button'

import colorLogo from '../../assets/logo/header-logo-color.png'
import whiteLogo from '../../assets/logo/header-logo-white.png'


import social_01 from "../../assets/images/social-01.svg"
import social_gallery_01 from "../../assets/images/social-01-gallery.svg"
import social_02 from "../../assets/images/social-02.svg"
import social_gallery_02 from "../../assets/images/social-02-gallery.svg"
import social_03 from "../../assets/images/social-03.svg"
import social_gallery_03 from "../../assets/images/social-03-gallery.svg"
import social_04 from "../../assets/images/social-04.svg"
import social_gallery_04 from "../../assets/images/social-04-gallery.svg"

const Header =(props)=>{

  const [links,setLinks] = useState([]);
  const [linkaddres,setLinkaddres] = useState('');
  const [show,setShow] = useState('')
  const [headerClass,setHeaderclass]=useState('dark');

  useEffect(()=>{

    // window.scrollTo(0, 0);
    
    if(show!==''){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflowY = 'scroll';
      document.body.style.overflowX = 'hidden';
      onScrollFunction()
    }
  
  },[show])

  useEffect(()=>{

    let header = document.querySelector(".top-header");
    let selectSection = document.getElementsByTagName("section");

    header.classList.remove("light");
    header.classList.remove("dark");

    header.classList.add(selectSection[0].getAttribute("data-theme"));

    setLinks(selectSection[0]?.getAttribute("links")?.split(",")||[])
    setLinkaddres(selectSection[0]?.getAttribute("linkaddres")?.split(",")||[])

    window.addEventListener("scroll", () => { onScrollFunction() })
      
  },[])


  const onScrollFunction=()=>{

    let header = document.querySelector(".top-header");
    let selectSection = document.getElementsByTagName("section");

    for (let i = 0; i < selectSection.length; i++) {

      if( selectSection[0].getBoundingClientRect().top !== 0 ){
        header.classList.add("on-scroll-header");
      }else{
        header.classList.remove("on-scroll-header");
      }

      if( props.page === "home" && selectSection[i].getBoundingClientRect().top <= 10 ){
        if( selectSection[i].getAttribute("links") !== null ){ 
          setLinks(selectSection[i]?.getAttribute("links")?.split(","))
          setLinkaddres(selectSection[i]?.getAttribute("linkaddres")?.split(","))
        }
      }
      
      if ( selectSection[i].getBoundingClientRect().top <= 10 ) {

        selectSection[i].classList.add("active");

        for (let j = 0; j < selectSection.length; j++) {

          if (j !== i) {
            selectSection[j].classList.remove("active");
          }

        }

        
        // // header.style.backdropfilter = 'opacity(50%)'
        header.style.backgroundColor = selectSection[i].getAttribute("background-color")
        header.classList.remove("light");
        header.classList.remove("dark");
        header.classList.add( selectSection[i].getAttribute("data-theme") );

      }

    }
  }

  const MainLinks =[
    {name:"Who we are",link:'/who-we-are'},
    {name:"Our Products",link:'/our-products'},
    {name:"Our Services",link:'/our-services'},
    {name:"Our Industry",link:'/our-industry'},
    {name:"Our Teams",link:'/our-teams'},
    {name:"ProSEED",link:'/freston-proceed'},
  ]

  const MinorLinks =[
    {name:"Career",link:'/careers'},
    {name:"Blogs",link:'/blogs'},
    {name:"Life @ Freston",link:'/life-at-freston'},
  ]
   
  return(
    <header className="header" >

      <div className={`top-header ${ show === 'show' ? "full-display":"dark" }`} >
        <div className="row">
          <div className="col-md-12">
            <nav className="nav">
              <a href="/" className="main-logo">
                <img
                  className="dark"
                  src={colorLogo}
                  alt=""
                />
                <img
                  className="white"
                  src={whiteLogo}
                  alt=""
                />
              </a>
              <ul className="navbar-nav">
              {
                    links.map((item,index) => {
                      return (
                        <li key={index} >
                          <Link to={`${linkaddres[index]}`} >
                            {item}
                          </Link>
                        </li>
                      )
                    })
                }
              </ul>
              <button className="nav-button" onClick={()=>{setShow('show')}}>
              {[1,2,3,4,5,6,7,8,9].map((item)=>{
                  return(
                    <span key={item}/>
                  )
                })}
              </button>
            </nav>
          </div>
        </div>
      </div>
      
      <div className={`main-menu ${show}`} onClick={()=>{setShow('')}}>
        
        <div className="top-section">
          <Link to="/" className="top-section-logo">
            <img src={whiteLogo} alt="" />
          </Link>
          <button className="close-menu" onClick={()=>{setShow('')}} />
        </div>

        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-8">
              <ul className="main-nav">
                {
                  MainLinks.map((item,index)=>{
                    return(
                      <li  key={index}>
                          <Link className="main-nav-item" to={`${item.link}`} >
                            {item.name}
                          </Link>
                      </li>

                    )
                  })
                }
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="sub-menu">
                {
                  MinorLinks.map((item,index)=>{
                    return(
                      <li key={index}>
                        <Link key={index} to={`${item.link}`} >
                        {item.name}
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
              <div className="let-connect-button-container" >
                  <ButtonConnect to="/contact-us" />
              </div>
            </div>
          </div>
          <hr className="hr" />
          <ul className="nav-social">
                
            <li>
                <a href="https://www.instagram.com/frestonanalytics" target="_blank" >
                <img className='default' src={social_01} alt="" />
                <img className='active' src={social_gallery_01} alt="" />
              </a>
            </li>
            <li>
               <a href="https://twitter.com/frestonanalytic" target="_blank">
                <img className='default' src={social_02} alt="" />
                <img className='active' src={social_gallery_02} alt="" />
              </a>
            </li>
            <li>
                <a  href="https://www.facebook.com/frestonanalytics" target="_blank">
                <img className='default' src={social_03} alt="" />
                <img className='active' src={social_gallery_03} alt="" />
              </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/frestonanalytics" target="_blank" >
                <img className='default' src={social_04} alt="" />
                <img className='active' src={social_gallery_04} alt="" />
              </a>
            </li>

                {/* <li>
                  <a href="">
                    <img src="assets/images/social-01.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src="assets/images/social-02.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src="assets/images/social-03.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src="assets/images/social-04.svg" alt="" />
                  </a>
                </li> */}

              </ul>
        </div>
        
      </div>

    </header>
  )
}

export default  Header

