import React, { useRef, useLayoutEffect } from 'react';
import Layout from '../../../components/layout';
import LetConnect from '../../../components/common/lets-coonect';
import { ButtonLink } from '../../../components/ui/button'
import DispalySection from '../../../components/common/service-industry-main-display'
import Card from '../../../components/ui/card'
import WordsFromPeople from '../../../components/common/words-from-people';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ButtonConnect } from "../../../components/ui/button"

import improve_security from "../../../assets/png-icon/improve-security.png"
import enhances_scalability from "../../../assets/png-icon/enhances-scalability.png"
import scalability_flexibility from "../../../assets/png-icon/scalability-flexibility.png"
import distributed_network from '../../../assets/png-icon/distributed-network.png'
import setting_icon from "../../../assets/png-icon/setting.png"
import gradient_circle_1 from "../../../assets/png-icon/gradient-circle-1.png"
import gradient_circle_2 from "../../../assets/png-icon/gradient-circle-2.png"

import case_study_2 from "../../../assets/images/case_study_02.png"
import case_study_3 from "../../../assets/images/case-study_03.png"
import background_image_3 from "../../../assets/background-images/background_03.png"
import background_image_2 from "../../../assets/background-images/background_02.png"

import implementation_processing_1 from "../../../assets/images/implementation_processing_01.png"
import implementation_processing_2 from "../../../assets/images/implementation_processing_02.png"
import implementation_processing_3 from "../../../assets/images/implementation_processing_03.png"
import implementation_processing_4 from "../../../assets/images/implementation_processing_04.png"
import implementation_processing_5 from "../../../assets/images/implementation_processing_05.png"

import client from '../../../assets/png-icon/client.png'
import gateway from '../../../assets/png-icon/gate-way.png'
import backend from '../../../assets/png-icon/backend.png'
import swingarrow from '../../../assets/png-icon/swing-arrow.png'
import user_handup from '../../../assets/png-icon/user-two-hand-up.png'

import working_progress from '../../../assets/images/gif/under-construction.gif'

import './api-gateway.scss'
import WorkingProgress from '../../../components/common/working-progress';

const metatag = {
    "title": "Case Study"
}

// component: <CoreValueSecond />,
// clasname: 'core-value active',
// theme: 'light',
// links: ``

const ApiGateWay = () => {

    const articleRef = useRef();

    useLayoutEffect(() => {

        const ctx = gsap.context(() => {
            // Target the two specific elements we have asigned the animate class
            ScrollTrigger.create({
                trigger: ".sticky-top",
                start: "top top",
                end: "+=2730",
                markers: false,
                pin: false
            })
        }, articleRef);// <- Scope!

        let item = gsap.utils.toArray(".item");

        item.forEach((div) => {

            console.log("div", div.getAttribute("href"))

            // let element = document.querySelector(div.getAttribute("href"));

            ScrollTrigger.create({
                trigger: '.item',
                start: "top center",
                end: "bottom center",
                onToggle: self => self.isActive && setActive(self),
                markers: false // true
            });

        })

        return () => ctx.revert();

    }, []);

    const setActive = (self) => {
        console.log("active", self)
    }

    return (
        <Layout page="api-gateway" metatag={metatag}>
            <article className='api-gateway'>
                
                <DispalySection image={background_image_2}  title={ <div> Unique capabilities of API gateway: </div> } subtitle={<>From strategy to execution, we help you achieve success</>} />
                <WorkingProgress />
            </article>
        </Layout>
    )
}

export default ApiGateWay;