import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const newElement =()=>{
  
        // const newDiv = document.createElement("div");
        // const slDiv = document.createElement("div");
        // const titleDiv = document.createElement("div");

        // const slNo = document.createTextNode(`${index<10?0:""}${index+1}`);
        // const title = document.createTextNode(`${self?.trigger?.attributes?.title?.value}`);

        // slDiv.appendChild(slNo);
        // slDiv.classList.add("breadcrumb-slNo");
        // titleDiv.appendChild(title);
        // titleDiv.classList.add("breadcrumb-title");
        // newDiv.classList.add("breadcrumb-content");
        // newDiv.appendChild(slDiv);
        // newDiv.appendChild(titleDiv);
        // div.replaceChildren(newDiv);
}


export default function breadCrumbsPlugin(offsetHeight) {

    gsap.timeline({
        scrollTrigger: {
          trigger: ".breadcrumb-container",
          start: "top top",
          end: `+=${ offsetHeight - 730 }`,
          scrub: 1,
          pin: true,
          markers: false // true
        }
      });

    let breadCrumbs = gsap.utils.toArray(".breadcrumb-bar");
    // let breadCrumbs = gsap.utils.toArray(".breadcrumb-bar");
    
    breadCrumbs.forEach((div,index) => {



    let element = document.querySelector(div.getAttribute("href"));
    let activeitem = gsap.utils.toArray(".breadcrumb-conttent-active");

    ScrollTrigger.create({
        trigger: element,
        start: "top center",
        end: "bottom center",
        onToggle: self => self.isActive && setActive(div,self),
        markers: false // true
      });

      breadCrumbs.forEach((el,index)=>{
        el.classList.remove('breadcrumb-bar-active')
        el.classList.add('breadcrumb-bar')
      })

    function setActive(div,self) {

      activeitem.forEach((el,index)=>{
        el.style.backgroundColor = self?.trigger?.attributes?.theme?.value === 'light' ? 'rgb(191, 187, 187)':'rgba(255, 255, 255, 0.6)'
      })

      breadCrumbs.forEach((el,index)=>{
        
        el.style.color = self?.trigger?.attributes?.theme?.value === 'light' ? 'black': 'white';
        el.style.backgroundColor = self?.trigger?.attributes?.theme?.value === 'light' ? "rgba(191, 187, 187, 0.2)" :"rgba(255, 255, 255, 0.2)"
        el.style.border = '1px solid rgba(191, 187, 187, 0.2)'

        if( el.getAttribute('href') === `#${self?.trigger?.attributes.id.value}`){
          div.classList.remove('breadcrumb-bar')
          div.classList.add('breadcrumb-bar-active')
          div.style.backgroundColor = 'transparent';
          div.style.border='none';
        }else{
          el.classList.remove('breadcrumb-bar-active')
          el.classList.add('breadcrumb-bar')
        }
        
      });

    }

    });
}