import React from 'react';
import {ButtonConnect} from "../ui/button";
 
const LetConnect = (props) => {

    return(
        <section className='let-connect-us' data-theme="light" >
            <div className="container" >
     
                    <div className="title-container">
                        <h1 className="let-connect-title">{  props.title || <> Okay enough about us, <br/> Lets talk about you</>}</h1>
                    </div>
                    
                    <div className="button-container">
                        <div className='button-center'>
                            <ButtonConnect labelcolor="black"  {...props} />
                        </div>
                    </div>
                    
            </div>
        </section > 
    );
}
 
export default LetConnect;