export function scrollUpDown(element) {

    var lastScrollTop = 0;

    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    element.addEventListener("scroll", function(){
        // or window.addEventListener("scroll"....

        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
            if (st > lastScrollTop){
            // downscroll code
                //   if(returnIndex(section_active)+1<childrenArray.length){
                //     gsap.to( window, { duration: 1, scrollTo: linkST[returnIndex(section_active)+1].start, overwrite: "auto" });
                //  }
        } else if (st < lastScrollTop) {
            console.log("down")
            // upscroll code
        } // else was horizontal scroll
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }, false);

}

export function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      console.log("upper top")
    } else {
        console.log("upper bottom")
    }
  }
  
  // When the user clicks on the button, scroll to the top of the document
 export function ScrollTotop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

export default ScrollTotop;