import React,{ useState } from 'react';
import Layout from '../../components/layout';
import FrestonLifeGallery from './freston-life-galley';
import WordsFromPeople from '../../components/common/words-from-people';
import DispalySection from '../../components/common/service-industry-main-display'

import LetConnect from '../../components/common/lets-coonect';

import blend from "../../assets/background-images/background_blend.png"
import background from "../../assets/background-images/background_08.png"

import social_01 from "../../assets/images/social-01.svg"
import social_gallery_01 from "../../assets/images/social-01-gallery.svg"
import social_02 from "../../assets/images/social-02.svg"
import social_gallery_02 from "../../assets/images/social-02-gallery.svg"
import social_03 from "../../assets/images/social-03.svg"
import social_gallery_03 from "../../assets/images/social-03-gallery.svg"
import social_04 from "../../assets/images/social-04.svg"
import social_gallery_04 from "../../assets/images/social-04-gallery.svg"

const metatag={
    "title":"Life @ freston"
}


const LifeAtFreston = () => {


    let [index,setIndex]=useState(0);

    let [direction ,setDirection] =useState('right')

    const [array,setArray]=useState(['IMG_0170.jpg','IMG_0250.jpg','IMG_0890.jpg'])

    const HandleLeftClick =()=>{
        console.log('left clicked')
       }
   
       const HandleRightClick =()=>{
           console.log("right clicked")
       }

    return(
        <Layout page="life-at-freston" metatag={metatag}>
            <article className='life-at-freston'>

                <DispalySection image={background}  title={<>Finding Joy in the Daily Grind:<br /> Capturing the Beauty of Office Life</>} subtitle={<>From strategy to execution, we help you achieve success</>} />
               
                <section className='life-at-freston-section' data-theme='light' >
                    <div className='section-container'>

                        <div className='inner-container'>

                            <div className='mobile-hide' >
                                <div className='social-media-container'>
                                    <p className='gallery-know-us-more'>To know us more</p>
                                    <div className="gallery-social">
                                        <li>
                                            <a href="https://www.instagram.com/frestonanalytics" target="_blank" >
                                            <img src={social_gallery_01} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/frestonanalytic" target="_blank" >
                                            <img src={social_gallery_02} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/frestonanalytics" target="_blank" >
                                            <img src={social_gallery_03} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/frestonanalytics" target="_blank" >
                                            <img src={social_gallery_04} alt="" />
                                            </a>
                                        </li>
                                    </div>
                                </div>
                                <div className='navigation-container'>
                                    <img className='navigation-arrow'  src='assets/images/left-arrow.png' onClick={()=>{HandleLeftClick()}} alt=""></img>
                                    <img className='navigation-arrow' src='assets/images/right-arrow.png' onClick={()=>{HandleRightClick()}} alt=""></img>
                                </div>
                                <div className='gallary-focus-item'>
                                    <img  src={`/assets/images/frestonlife/${array[0]}`} width={"100%"} height={"100%"} style={{objectFit:'cover',borderRadius:'6px'}} key={index} />
                                </div>
                                <p className='gallery-focus-item-date'>21 Mar 2024</p>
                                <p className='gallery-focus-item-label'>Industry Connect</p>
                            </div>

                            <div className='mobile-visible' >

                                <p className='gallery-title'> No page title can express <br/> <span>who and how we are</span></p>
                                <p className='gallery-text'> Freston Analytics is a technology services company providing specialised telecom software development, data analytics and integrated customer experience solutions. We help organisations innovate, scale and transform with turnkey and custom software services including contact centre solutions, security and tracking applications, third-party implementation and staff augmentation services.   </p>

                                <div className='gallery-item-container' >
                                    {
                                        array.map((item)=>{

                                            return(
                                                <div className='gallery-item' style={{ marginLeft:item===1 ?'0px':'10px'}}>
                                                    <img key={index} src={`/assets/images/frestonlife/${item}`} width={"100%"} height={"100%"} style={{ objectFit:'cover',padding:'2px' }} />
                                                </div>
                                            )

                                        })
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </section>


                <section className='words-from-people-section' data-theme="light">
                    <WordsFromPeople/>
                </section>

                <section className='life-at-freston-section' data-theme='light' >
                    <div className='section-container'>

                        <div className='inner-container'>

                            <div className='mobile-hide' >
                                <div className='social-media-container'>
                                    <p className='gallery-know-us-more'>To know us more</p>
                                    <div className="gallery-social">
                                        <li>
                                            <a href="https://www.instagram.com/frestonanalytics" target="_blank" >
                                            <img src={social_gallery_01} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/frestonanalytic" target="_blank" >
                                            <img src={social_gallery_02} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/frestonanalytics" target="_blank" >
                                            <img src={social_gallery_03} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/frestonanalytics" target="_blank" >
                                            <img src={social_gallery_04} alt="" />
                                            </a>
                                        </li>
                                    </div>
                                </div>
                                <div className='navigation-container'>
                                    <img className='navigation-arrow'  src='assets/images/left-arrow.png' onClick={()=>{HandleLeftClick()}} alt=""></img>
                                    <img className='navigation-arrow' src='assets/images/right-arrow.png' onClick={()=>{HandleRightClick()}} alt=""></img>
                                </div>
                                <div className='gallary-focus-item'>
                                    <img  src={`/assets/images/frestonlife/${array[0]}`} width={"100%"} height={"100%"} style={{objectFit:'cover',borderRadius:'6px'}} key={index} />
                                </div>
                                <p className='gallery-focus-item-date'>21 Mar 2024</p>
                                <p className='gallery-focus-item-label'>Industry Connect</p>
                            </div>

                            <div className='mobile-visible' >

                                <p className='gallery-title'> it's not just about the job, but also about your growth and well-being </p>


                                <div className='gallery-item-container' >
                                    {
                                        array.map((item)=>{

                                            return(
                                                <div className='gallery-item' style={{ marginLeft:item===1 ?'0px':'10px'}}>
                                                    <img key={index} src={`/assets/images/frestonlife/${item}`} width={"100%"} height={"100%"} style={{ objectFit:'cover',padding:'2px' }} />
                                                </div>
                                            )

                                        })
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

                {/* <section className='words-from-people-section' data-theme='light' >
                    <div className='mobile'>

                    </div>
                    </section> */}

                <LetConnect to="/contact-us" darkicon title={<div style={{whiteSpace:'nowrap'}}> <span>Start your career at <br/> Freston,</span>Let's improve the <br/> digital world together!</div>} buttonlabel="Apply Now" />
                
            </article>
        </Layout>
    )
}
 
export default LifeAtFreston;