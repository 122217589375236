import React, { useRef, useLayoutEffect } from 'react';
import Layout from '../../../components/layout';
import LetConnect from '../../../components/common/lets-coonect';
import DispalySection from '../../../components/common/service-industry-main-display'


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ButtonConnect } from "../../../components/ui/button"


import call_icon from "../../../assets/png-icon/call-quality.png"
import scalability_flexibility from "../../../assets/png-icon/scalability-flexibility.png"
import visibility from "../../../assets/png-icon/visibility.png"
import distributed_network from '../../../assets/png-icon/distributed-network.png'
import setting_icon from "../../../assets/png-icon/setting.png"
import gradient_circle_1 from "../../../assets/png-icon/gradient-circle-1.png"
import gradient_circle_2 from "../../../assets/png-icon/gradient-circle-2.png"

import case_study_2 from "../../../assets/images/bigdata-gmv/gmv-intro.png"
import case_study_3 from "../../../assets/images/bigdata-gmv/challenges.png"
import case_study_4 from "../../../assets/images/bigdata-gmv/alltogether.png"

import implementation_processing_1 from "../../../assets/images/implementation_processing_01.png"
import implementation_processing_2 from "../../../assets/images/implementation_processing_02.png"
import implementation_processing_3 from "../../../assets/images/implementation_processing_03.png"
import implementation_processing_4 from "../../../assets/images/implementation_processing_04.png"
import implementation_processing_5 from "../../../assets/images/implementation_processing_05.png"

import logstash from '../../../assets/png-icon/Logstash.png'
import Elasticsearch from '../../../assets/png-icon/Elasticsearch.png'
import Kibana from '../../../assets/png-icon/Kibana.png'

import './big-data-insight.scss'

import background from '../../../assets/background-images/background_06.png'

const metatag = {
    "title": "Case Study"
}

const CaseStudy = () => {

    const articleRef = useRef();

    useLayoutEffect(() => {

        const ctx = gsap.context(() => {
            // Target the two specific elements we have asigned the animate class
            ScrollTrigger.create({
                trigger: ".sticky-top",
                start: "top 150px",
                end: "+=2730",
                markers: false,
                pin: true
            })
        }, articleRef);// <- Scope!

        let item = gsap.utils.toArray(".scroll-content-item");

        item.forEach((div) => {

            let active = document.querySelector(div.getAttribute("href"));

            ScrollTrigger.create({
                trigger: div,
                start: "top center",
                end: "bottom center",
                onToggle: self => self.isActive && setActive(self, active),
                markers: false // true
            });

        })

        return () => ctx.revert();

    }, []);

    const setActive = (self, active) => {

        let item = gsap.utils.toArray(".scroll-content-item");

        item.forEach((div) => {

            let element = document.querySelector(div.getAttribute("href"));

            if (element !== null) {
                element.style.opacity = 0.5;
                element.style.paddingLeft = '0px';
                element.style.borderLeftStyle = "none";
                element.style.borderLeftWidth = "0px";
                element.style.borderLeftColor = "white";
                element.style.paddingLeft = '0px';
            }

        })

        if (active != null) {
            active.style.opacity = 1;
            active.style.paddingLeft = '20px';
            active.style.borderLeftStyle = "solid";
            active.style.borderLeftWidth = "5px";
            active.style.borderLeftColor = "#F3684A";
            active.style.paddingLeft = '20px';
        }

    }

    return (
        <Layout page="global-managed-service" metatag={metatag}>
            <article className="global-managed-service" >

                <DispalySection image={background} title={<div> <span className='case-study' >CASE STUDY</span> <br/> GMV DATALAKE SOLUTION FOR TELECOM CLIENT</div>} subtitle={<>From strategy to execution, we help you achieve success</>} />

                <section className='overview-section' data-theme={'light'} >

                    <div className='section-container'>
                        <div>
                            <p className='title'>CASE STUDY</p>
                            <p className='sub-title'>Global Managed Voice Network</p>
                        </div>
                        <div>
                            <p className='title'>INDUSTRY</p>
                            <p className='sub-title'>Media and Communication</p>
                        </div>
                        <div>
                            <p className='title'>PLATFORM</p>
                            <p className='sub-title'>Big data</p>
                        </div>
                        <div>
                            <p className='title'>SERVICES</p>
                            <p className='sub-title'>Dashboard Creation</p>
                        </div>
                    </div>

                </section>

                <section className='introduction-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-content' >
                            <p className='label' >INTRODUCTION</p>
                            <p className='title' >Global Managed Voice Network <span >  (GMV) </span> </p>
                            <div className='container-image' >
                                <div className='image-container' >
                                    <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                    <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                    <img className='display-image' src={case_study_2} alt="" />
                                </div>
                            </div>
                            <p className='description'> Our client is a multinational corporation with a presence in several countries. The company recently expanded its operations and needed a robust Managed Voice Network to handle their communication needs efficiently. They decided to use the ELK stack to centralize and monitor voice- related data to improve call quality, troubleshoot issues faster, and gain valuable insights into their communication patterns. </p>
                        </div>

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={case_study_2} alt="" />
                            </div>
                        </div>

                    </div>

                </section>

                <section className='challenges-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <img className='circle-decoration' src={gradient_circle_2} alt="" />
                            <img className='display-image' src={case_study_3} alt="" />
                        </div>

                        <div className='container-content' >
                            <p className='label' >CHALLENGES</p>
                            <p className='title' >Against the Odds:<span style={{ color: '#F3684A' }}> Embracing Challenges </span> </p>
                        </div>

                        <div className='card-container' >

                            <div className='card' >
                                <img className='card-icon' src={distributed_network} />
                                <p className='title' >Distributed Network:</p>
                                The company had multiple offices across the globe, resulting in a complex and distributed voice network infrastructure.
                            </div>
                            <div className='card' >
                                <img className='card-icon' src={call_icon}/>
                                <p className='title' >Call Quality Issues:</p>
                                They were experiencing occasional call quality issues, dropped calls, and connectivity problems, but couldn't pinpoint the root causes quickly.
                            </div>
                            <div className='card' >
                                <img className='card-icon' src={visibility}  />
                                <p className='title' >Lack of Visibility:</p>
                                There was limited visibility into the overall health and performance of the voice network due to the absence of a centralized monitoring system.
                            </div>

                        </div>

                    </div>

                </section>

                <section className='gears-of-project-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-head' >
                            <p className='title' > Gears of the Project: <span > Tools Used </span> </p>
                            <p className='description' >To address these challenges, the company decided to implement a Managed Voice Network using the ELK stack.</p>
                            {/* <div className='button-center'>
                                <ButtonConnect labelcolor="black" darkicon />
                            </div> */}
                        </div>

                        <div className='container-content' >

                            <div className='content-item' >
                                <img className='content-icon' src={logstash} />
                                <p className='title' > Logstash</p>
                                <p className='description' > Logstash was deployed to collect and process the voice data from various sources, such as VoIP servers, SIP trunks, and other communication endpoints. It helped in parsing, filtering, and transforming the data before sending it to Elasticsearch for indexing.</p>
                            </div>
                            <div className='content-item' >
                                <img className='content-icon' src={Elasticsearch} />
                                <p className='title' > Elastic Search</p>
                                <p className='description' >Elasticsearch was used to store and index the voice-related data, including call logs, call quality metrics, call duration, and other relevant information.</p>
                            </div>
                            <div className='content-item' >
                                <img className='content-icon' src={Kibana} />
                                <p className='title' > Kibana </p>
                                <p className='description' > Kibana was used as the front-end visualization tool to create real-time dashboards and reports. It provided the company's IT and network teams with insights into call volumes, call quality trends, latency, and other performance metrics.</p>
                            </div>

                        </div>
                    </div>

                </section>

                <section className='implementation-process-section' data-theme={'light'} ref={articleRef}>

                    <div className='section-container' >

                        <div className='sticky-top' >
                            <p className='title' >Turning Strategy into Reality: <span > Implementation Process </span> </p>
                            <div className='item-container'  >
                                <p className='content-item' id="data-source"  > <span >01</span> Data Source Integration</p>
                                <p className='content-item' id="data-proccess"  > <span style={{ paddingRight: '20px' }}>02</span> Data Process</p>
                                <p className='content-item' id="data-storage"  > <span style={{ paddingRight: '20px' }}>03</span> Data Storage and Indexing</p>
                                <p className='content-item' id="dashboard-creation"  > <span style={{ paddingRight: '20px' }}>04</span> Dashboard Creation</p>
                                <p className='content-item' id="alerts-and-monitoring"  > <span style={{ paddingRight: '20px' }}>05</span> Alerts and Monitoring</p>
                            </div>
                        </div>

                        <div className='container-content' >

                            <div className='item-container-scroll'>

                                <p className='title' >Turning Strategy into Reality: <span > Implementation Process </span> </p>

                                <div className='scroll-content-item' href="#data-source" >
                                    <p className='content-item-mobile-title' id="data-source"  > <span >01</span> Data Source Integration</p>
                                    <img className='display-image' src={implementation_processing_1} ></img>
                                    <p className='title' >Data Source<span style={{ color: '#5A536B' }}> Integration </span> </p>
                                    <p className='description'>
                                        The company integrated Logstash agents with various voice network components, including PBX systems, SIP trunks, gateways, and endpoints. Logstash was configured to collect call logs and related metrics continuously.
                                    </p>
                                </div>
                                <div className='scroll-content-item' href="#data-proccess" >
                                    <p className='content-item-mobile-title' id="data-proccess"  > <span >02</span> Data Process</p>
                                    <img className='display-image' src={implementation_processing_2} ></img>
                                    <p className='title' >Data <span style={{ color: '#5A536B' }}> Process </span> </p>
                                    <p>
                                        Logstash was configured with the appropriate filters to parse and process the collected data. It enriched the data by adding relevant metadata and tags to identify the source and type of each log entry.
                                    </p>
                                </div>
                                <div className='scroll-content-item' href="#data-storage" >
                                    <p className='content-item-mobile-title' id="data-storage"  > <span >03</span> Data Storage and Indexing</p>
                                    <img className='display-image' src={implementation_processing_3} ></img>
                                    <p className='title' >Data <span > Storage and Indexing </span> </p>
                                    <p className='description'>
                                        Processed data was sent to Elasticsearch, where it was indexed and made searchable. Elasticsearch's indexing capabilities allowed for fast and efficient data retrieval.
                                    </p>
                                </div>
                                <div className='scroll-content-item' href="#dashboard-creation" >
                                    <p className='content-item-mobile-title' id="dashboard-creation"  > <span style={{ paddingRight: '20px' }}>04</span> Dashboard Creation</p>
                                    <img className='display-image' src={implementation_processing_4} ></img>
                                    <p className='title' >Dashboard  <span > Creation</span> </p>
                                    <p className='description'>
                                        Kibana dashboards were created to visualize call volumes, call quality metrics, network health, and other important indicators. Customized visualizations and charts were utilized to make data interpretation easier.
                                    </p>
                                </div>
                                <div className='scroll-content-item' href="#alerts-and-monitoring" >
                                    <p className='content-item-mobile-title' id="alerts-and-monitoring"  > <span >05</span> Alerts and Monitoring</p>
                                    <img className='display-image' src={implementation_processing_5} ></img>
                                    <p className='title' >Alerts   <span > and Monitoring </span> </p>
                                    <p className='description'>
                                        The ELK stack was set up to trigger real-time alerts whenever specific call quality thresholds were breached or unusual call patterns were detected. This proactive monitoring helped the company's IT team respond quickly to potential issues.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section className='outcome-section' data-theme={'light'} links={''} >

                    <div className='section-container'>

                        <div className='container-head' >
                            <p className='title' >OUTCOME</p>
                            <p className='subtitle'  > AI solutions can be used across <br /> <span > almost any sector or industry </span> </p>
                            <p className='subtitle' > The implementation of the Managed Voice Network using the ELK stack resulted in several benefits for the company </p>
                        </div>

                        <div className='container-content'  >

                            <div className='content-item' >
                                <img className='item-icon' src={setting_icon} />
                                <p className='title' >Improved Troubleshooting</p>
                                With centralized logging and real-time monitoring, the IT team could quickly identify and troubleshoot voice network issues, reducing downtime and improving overall service quality.
                            </div>

                            <div className='content-item' >
                                <img className='item-icon' src={call_icon} />
                                <p className='title' >Enhanced Call Quality</p>
                                The company could proactively monitor call quality metrics, identify areas of improvement, and optimize the network for better call experiences.
                            </div>

                            <div className='content-item' >
                                <img className='item-icon' src={visibility} />
                                <p className='title' >Global Network Visibility</p>
                                The unified dashboard provided a single pane of glass view into the global voice network performance, helping the management make informed decisions and allocate resources effectively.
                            </div>

                            <div className='content-item' >
                                <img className='item-icon' src={scalability_flexibility} />
                                <p className='title' >Scalability and Flexibility</p>
                                The ELK stack's scalability allowed the company to add more data sources and expand the monitoring capabilities as their network grew.
                            </div>


                        </div>

                    </div>

                </section>

                <section className='conclusion-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={case_study_4} alt="" />
                            </div>
                        </div>

                        <div className='container-content' >
                            <p className='label' >CONCLUSION</p>
                            <p className='title' >  Putting it All Together: Case Study Conclusion</p>
                            <div className='container-image' >
                                <div className='image-container' >
                                    <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                    <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                    <img className='display-image' src={case_study_4} alt="" />
                                </div>
                            </div>
                            <p className='description' >  By leveraging the ELK stack, the global company successfully implemented a Managed Voice Network that enhanced call quality, improved network visibility, and streamlined troubleshooting processes. The ELK stack's real-time monitoring capabilities and centralized data storage were instrumental in maintaining a reliable and high-performing voice network across their global operations. </p>
                        </div>

                    </div>

                </section>

                <LetConnect to="/contact-us" darkicon title={<>Okay enough about us, Lets talk about you</>} buttonlabel="LET'S CONNECT" />

            </article>
        </Layout>
    )
}

export default CaseStudy;