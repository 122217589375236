import React from 'react';

import { ButtonConnect,ButtonLetConnect } from '../ui/button'

import background from '../../assets/images/banner-00.png'


const DispalySection=(props)=>{
    
    return(
        <section className="main-section-display active" style={{background:`url(${props?.image|| background })`,objectFit:'contain',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'bottom'}}  data-theme="dark">
            <div className="continer" style={{backgroundColor:'rgba(0, 0, 0, 0.54)',display:'flex',width:'100%',height:'100%'}} >

                <div className="row">
                    <div className="col-md-12">
                    <h1 className="section-title">
                        {props?.title}
                    </h1>
                    <h4 className="sub-title">
                        {props?.subtitle}
                    </h4>
                    </div>
                </div>
               
                {/* <div className='button-container'  >
                    <ButtonConnect />
                </div> */}

            </div>
        </section>     
    )
}

 export default DispalySection;


