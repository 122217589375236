
import Layout from '../../components/layout';
import React from 'react';

import { useNavigate } from 'react-router-dom';

import LetConnect from '../../components/common/lets-coonect';

// const IMAGE_BASE_URL = "http://localhost:4000/images/blogs";

const IMAGE_BASE_URL = "/assets/images/blogs/"

const metatag={
    "title":"Blogs"
    }

// const blogs=[
//     {
//         title:"Blockchain Technology in real world scenarios",
//         description:"Blockchain technology is one of the most talked about technologies right now. It is the underlying.",
//         author:"Jawid Musthafa",
//         date:"21, Mar 2024"
//     },
//     {
//         title:"How AI can help accelerate the advancement in Cyber Security",
//         description:"People are turning to artificial intelligence and machine learning for solutions to cybersecurity problems. That's because, in spite of constant cyberattacks, AI is continuing to improve its ability to protect network security",
//         author:"Sahal Abdul Ghafur",
//         date:"13, July 2024"
//     },
//     {
//         title:"Code Quality",
//         description:"Why Quality Code is Important: A blog around code quality and the importance of following clean coding principles.",
//         author:"Jawid Musthafa",
//         date:"21, Mar 2024"
//     },
// ]

const BlogInnerPage = () => {

    const navigate = useNavigate();

    return (
        <Layout page="gpdr" metatag={metatag}>
            <article>
                <section className='blog-page-section' data-theme='light' >
                    <div className='container' >
                        <h1 className='title'>General Data Protection Regulation</h1>
                        {/* <p className='sub-title' >Expand your knowledge with the help of these informative.</p> */}
                    </div>
                </section>
                <section style={{ textAlign: "left",width:'70%',margin:'auto',padding:'100px 0px'}}>
  <h4>1. When users submit an enquiry through our website</h4>
  <p>
    When an enquiry is submitted through our website, we ask users for their
    name, contact telephone number, email address, comments (and IP adress?)
  </p>
  <p>
    We use this information to respond to their query, including providing them
    with any requested information about our products and services. We may also
    email them several times after their enquiry in order to follow up on their
    interest and ensure that we have answered it to their satisfaction. We will
    do this based on our legitimate interest in providing accurate information
    prior to the customer.
  </p>
  <p>
    The enquiry is stored and processed as an email? which is hosted by
    Microsoft within the European Economic Area (EEA)?. It is also logged on to
    our CRM system database on a cloud server based in the United Kingdom.?
  </p>
  <p>
    We do not use the information provided to make any automated decisions that
    might affect the user.
  </p>
  <p>
    We keep enquiry emails for two years, after which they are securely archived
    and kept for seven years, when we delete them. CRM records are kept for
    three years after the last contact with the customer.
  </p>
  <h4>2. When users sign up to receive our newsletter</h4>
  <p>
    When users sign up to receive our newsletter, we ask for their name and
    email address.
  </p>
  <p>
    We will ask for their consent to use their name and email address to email
    them our newsletter which contains information about our services and other
    information which we feel might be of interest to them.
  </p>
  <p>
    They can withdraw their consent at any time, and we will stop sending them
    the newsletter. We do not use the information they provide to make any
    automated decisions that might affect them.
  </p>
  <p>
    We keep their personal data for as long as we produce and distribute our
    newsletter. If they withdraw their consent, we will mark their details so
    that they are not used and delete them after two years.
  </p>
  <h4>3. When users submit comments on our blog</h4>
  <p>
    We record the users name, email address and IP (Internet Protocol) address,
    which is the address of their computer on the Internet.
  </p>
  <p>
    Their name and the comments submitted (but not their email address or IP
    address) may be publicly displayed on our website. We will do this based on
    our legitimate interest in marketing our products and services.
  </p>
  <p>
    Their comments will be stored in our secure servers based within the
    European Economic Area (EEA). We do not use the information they provide to
    make any automated decisions that might affect them.
  </p>
  <p>
    Their personal data is stored for as long as we are marketing the services
    that the blog refers to, after which it is deleted. They may ask us to
    remove their comments at any time.
  </p>
  <h4>
    4. When applying for a role with us via the website Career’s page or Jobsite
    Adverts
  </h4>
  <p>
    We collect the candidate’s CV which might include their name, address, email
    address, contact phone number and DoB. The CVs will be saved on the data
    controller as a soft copy by the data controller and might be printed out,
    if necessary, and will be securely shredded at the end of 6 months.
  </p>
  <h4>5. When commencing employment with u</h4>
  <p>
    We record the employee’s name, address, email IDs, Date of Birth, National
    Insurance Number and Bank details for payroll, tax and pension processing.
  </p>
  <p>Soft and hard copies of the following are retained of the following:</p>
  <ul>
    <li>CV</li>
    <li>Degree Certificate(s)</li>
    <li>ID Proof (Passport/Driving Licence)</li>
    <li>Address Proof (Utility bill/Council Tax bill)</li>
    <li>Biometric Data</li>
  </ul>
  <p>
    Soft copies are shared via email and stored securely on the Data
    Controller’s desktops and on a third-party HR Portal – People HR, who have
    contractually committed to providing appropriate safeguards for the
    candidate’s personal data which means it will be protected in line with the
    legal requirements of the European Union.
  </p>
  <p>
    When employing overseas resources who require sponsorship to work in the UK,
    their details will be shared with UKVI via a secure Sponsor Management
    System, and verified by our Immigration Lawyers, Migrate UK, as required.
  </p>
  <p>
    Hard copies of the above documents are filed and stored securely by the Data
    Controller. Once the employee ceases employment with us, all the above
    records are retained for six years before they are deleted or securely
    disposed. The records detailed below are also retained for the mentioned
    legal period and destroyed at the end of this period:
  </p>
  <ul>
    <li>
      Working time records: Kept for 2 years from the date the records refer to.
    </li>
    <li>
      Payroll records: Kept for 3 years from the end of the tax year that they
      relate to.
    </li>
    <li>
      Maternity, Paternity or Shared Parental Pay records: Kept for 3 years
      after the end of the tax year that the payment stopped.
    </li>
  </ul>
  <p>
    The soft copies are deleted after the stipulated period and the hard copies
    are securely shredded.
  </p>
                </section>
                <LetConnect darkicon to="/contact-us" title="Let us know how we can assist you" buttonlabel="LET'S CONNECT" />
            </article>
        </Layout>
    )
}

export default BlogInnerPage