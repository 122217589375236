import React ,{ useEffect, useRef }from 'react'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layout from '../../components/layout'
import { useNavigate } from 'react-router-dom'
import { useLocation  } from 'react-router-dom'

import LetConnect from '../../components/common/lets-coonect'


const metatag={
  "theme-color":"000000",
  "title":"Who we are",
  "description":"",
  "keywords":"",
  "og:site_name":"",
  "og:title":"When Great Minds Don't Think Alike",
  "og:description":"How much does culture influence creative thinking",
  "og:url":"http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html",
  "og:type":"article",
  "og:image":"http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg",
  "og:image:width":"20px",
  "og:image:height":"20px",
  "twitter:card":"",
  "twitter:domain":"",
  "twitter:title":"",
  "twitter:description":"",
  "twitter:creator":"",
  "twitter:image":""
}

const WHOWEARE = () => {

  const navigate = useNavigate();

  const articleRef = useRef();

  const location = useLocation();

  useEffect(() => {

  let sticky_one = document.getElementById('sticky-one');
  let sticky_two = document.getElementById('sticky-two');
  let core_value_one = document.getElementById('core-value-one');
  let core_value_two = document.getElementById('core-value-two');

  let coreOne = document.getElementById("coreOne");
  let coreTwo = document.getElementById("coreTwo");
  let coreThree = document.getElementById("coreThree");
  let coreFour = document.getElementById("coreFour");

  let targetOne = document.getElementById("target-one");
  let targetTwo = document.getElementById("target-two");
  let targetThree = document.getElementById("target-three");
  let targetFour = document.getElementById("target-four");

  let NodeArray = [coreOne,coreTwo,coreThree,coreFour];
  let targetArray = [targetOne,targetTwo,targetThree,targetFour];

  document.addEventListener("scroll", ()=>{

    NodeArray.forEach((e,index)=>{

      if( e.getBoundingClientRect().top < 100 && e.getBoundingClientRect().top > -600 ){
        targetArray[index].classList.add('active')
      }else{
        targetArray[index].classList.remove('active')
      }

    })

    if( core_value_one.getBoundingClientRect().top > 0){

      sticky_one.classList.add('core_value_one_before-entering')
      sticky_one.classList.remove('core_value_one_on-positon')
      sticky_one.classList.remove('core_value_one_after-leaving')

      // sticky_one.style.marginTop = '90px'
      // sticky_one.style.position = 'relative'
    }

    if( core_value_one.getBoundingClientRect().top < 0 &&  core_value_one.getBoundingClientRect().top > -820 ){
 
      sticky_one.classList.remove('core_value_one_before-entering')
      sticky_one.classList.add('core_value_one_on-positon')
      sticky_one.classList.remove('core_value_one_after-leaving')

      // sticky_one.style.backgroundColor = 'red'
      // sticky_one.style.position = 'fixed'
    }

    if( core_value_one.getBoundingClientRect().top < -820){

      sticky_one.classList.remove('core_value_one_before-entering')
      sticky_one.classList.remove('core_value_one_on-positon')
      sticky_one.classList.add('core_value_one_after-leaving')

      // sticky_one.style.backgroundColor = 'green'
      // sticky_one.style.position = 'relative'
      // sticky_one.style.marginTop = '1100px'

    }

    if( core_value_two.getBoundingClientRect().top > 0){

      sticky_two.classList.add('core_value_two_before-entering')
      sticky_two.classList.remove('core_value_two_on-positon')
      sticky_two.classList.remove('core_value_two_after-leaving')

      // sticky_two.style.marginTop = '10px'
      // sticky_two.style.position = 'relative'
    }

    if( core_value_two.getBoundingClientRect().top < 0 &&  core_value_two.getBoundingClientRect().top > -2200 ){

      sticky_two.classList.remove('core_value_two_before-entering')
      sticky_two.classList.add('core_value_two_on-positon')
      sticky_two.classList.remove('core_value_two_after-leaving')

      // sticky_two.style.marginTop = '10px'
      // sticky_two.style.position = 'fixed'
    }

    if( core_value_two.getBoundingClientRect().top < -2200){

      sticky_two.classList.remove('core_value_two_before-entering')
      sticky_two.classList.remove('core_value_two_on-positon')
      sticky_two.classList.add('core_value_two_after-leaving')

      // sticky_two.style.position = 'relative'
      // sticky_two.style.marginTop = '2200px'
      
    }

  })


  }, []);


    navigate(0);

    return (
      <Layout page="who-we-are" metatag={metatag} defaulHeadertLinks={[]}>

        <article id="fullpage" data-bs-spy="scroll"  data-bs-target="#sectionsList" data-bs-offset="0">

                <section  id="whoAreWe-main" className="whoAreWe-main" data-theme="light" links="" >
                  <div className="container two">

                    <div className="row">

                      <div className="col-md-6">
                            <h2 className="section-title">
                              <b> We are a software solutions company,</b>
                            </h2>
                            <p className="section-text">
                              We take technology forward - helping organisations innovate, scale and transform with bespoke digital products and services.
                            </p>
                      </div>
                      <div className="col-md-6">
                        <div className="rotating">
                          <div className="rotating-circle" />
                        </div>
                      </div>

                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="section-box">
                          <div className="content">
                            <img src="assets/images/vision.png" alt="" />
                            <span className="head">Vision</span>
                          </div>
                          <div className="hover">
                            <div className="head">Vision</div>
                            <div className="content-col">
                              <span className="icon" />
                              <p className="text">
                                Offer value to our customers with cutting-edge telecom technology
                                and complement it with exceptional customer care and after-sales
                                service.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="section-box">
                          <div className="content">
                            <img src="assets/images/mission.png" alt="" />
                            <span className="head">Mission</span>
                          </div>
                          <div className="hover">
                            <div className="head">Mission</div>
                            <div className="content-col">
                              <span className="icon" />
                              <p className="text">
                              To help global organisations reap the best from
                              innovations in technology and data analytics using world-class software products and
                              services that can be seamlessly integrated into their existing systems
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </section>

                <section  id='core-value-one' className='core-values' data-theme='light' links="" >
                  <div className="container two">
                    <div className="row" >
                      <div className="col-md-6" ref={articleRef} >

                        <div id="sticky-one" className="sticky-top"  >
                          <h2 className="section-title">
                            Building Trust Through <b>Our Core Values</b>
                          </h2>
                          <p className="section-text ">
                            We believe trust is earned. We are honest and transparent in all
                            our dealings and endeavour to set realistic expectations about
                            what can be accomplished.
                          </p>
                        </div>
                      
                      </div>
                      <div className="col-md-6">
                        <div className="col-box">
                          <div className="head">
                            <i className="icon">
                              <img src="assets/images/core-icon-01.png" alt="" />
                            </i>
                            <h2 className="title">
                              Act with <span>Integrity</span>
                            </h2>
                          </div>
                          <h5 className="content">
                          We believe trust is earned. We are honest and transparent in all our dealings and endeavour to set realistic expectations about what can be accomplished.
                          </h5>
                        </div>
                        <div className="col-box">
                          <div className="head">
                            <i className="icon">
                              <img src="assets/images/core-icon-02.png" alt="" />
                            </i>
                            <h2 className="title">
                              Embrace <span> Challenges </span>
                            </h2>
                          </div>
                          <h5 className="content">
                          We see hurdles as opportunities to bring out creativity and come up with innovative solutions with the resources and tools available to us.
                          </h5>
                        </div>
                        <div className="col-box">
                          <div className="head">
                            <i className="icon">
                              <img src="assets/images/core-icon-03.png" alt="" />
                            </i>
                            <h2 className="title">
                              Clients <span>First</span>
                            </h2>
                          </div>
                          <h5 className="content">
                          We follow a client centric approach and make our decisions and measure outcomes based on how well it serves our customers.
                          </h5>
                        </div>
                        <div className="col-box">
                          <div className="head">
                            <i className="icon">
                              <img src="assets/images/core-icon-04.png" alt="" />
                            </i>
                            <h2 className="title">
                              Stoke <span>Curiosity</span>
                            </h2>
                          </div>
                          <h5 className="content">
                          We never settle for the obvious. We challenge our ideas to vet and refine them and create the best possible solutions for our customers.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section id="overview-section" className='overview-section' data-theme="light" links="light" >
                    <div className="bg-overview">
                    <div className="container two">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <h5 className="head">Overview</h5>
                          <ul className="overview-list">
                            <li>
                              <b className="label">100+</b>
                              <span className="value">Team Members &amp; Counting</span>
                            </li>
                            <li>
                              <b className="label">10+</b>
                              <span className="value">Market-Leading Clients</span>
                            </li>
                            <li>
                              <b className="label">03</b>
                              <span className="value">Offices Packed with creativity</span>
                            </li>
                            <li>
                              <b className="label">10+</b>
                              <span className="value">Live Projects in Market</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <img src="assets/images/overview.png" alt="" />
                        </div>
                      </div>
                    </div>
                    </div>
                </section>

                <section id='core-value-two' className='core-value' data-theme='light' links='' >
                    <div className="container">
                      <div className="row">

                        <div className="col-md-6" >
                          <div id='sticky-two' className="sticky-top">
                            <h2 className="section-title">
                              Choosing <span >FrestonAnalytics </span>: 
                              <br/> Here's Why 
                            </h2>
                            <ul id="coreNav" className="core-nav">
                              <li>
                                <a className="active" id="target-one" >
                                Our Approach
                                </a>
                              </li>
                              <li>
                                <a className="" id="target-two" >Creative Solutions</a>
                              </li>
                              <li>
                                <a className=""  id="target-three"  >Comprehensive Packages</a>
                              </li>
                              <li>
                                <a className=""  id="target-four"  >Customer Focus</a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          className="col-md-6 core-value-two"
                          data-bs-spy="scroll"
                          data-bs-target="#coreNav"
                          data-bs-offset={0}
                          tabIndex={0}
                        >

                          <div id="coreOne" className="core-col">
                            <i className="image">
                              <img src="assets/images/core-img-01.png" alt="" />
                            </i>
                            <h2 className="title">
                              <b> Navigating Success </b> Our Distinctive Methodology
                            </h2>
                            <p className="section-text ">
                              We care. We care about how our products and services make a difference to your organisation and your bottom line. This drives us to go the extra mile and deliver the right solution that fits your specific requirement.
                            </p>
                          </div>
                          <div id="coreTwo" className="core-col">
                            <i className="image">
                              <img src="assets/images/core-img-02.png" alt="" />
                            </i>
                            <h2 className="title">
                              Breaking <b> Boundaries </b>
                            </h2>
                            <p className="section-text ">
                              We make use of the latest in technology to come up with creative solutions that streamline processes,improve the productivity of your team and give you the competitive edge in a crowded marketplace.
                            </p>
                          </div>
                          <div id="coreThree" className="core-col">
                            <i className="image">
                              <img src="assets/images/core-img-03.png" alt="" />
                            </i>
                            <h2 className="title">
                              <b>All-Inclusive Excellence</b>: Your Complete Solution Partner
                            </h2>
                            <p className="section-text ">
                              In addition to providing you with the right software tools and support services, we take the hassle out of your new digital system rollout with add-on services such as call centres and outsourced resources to monitor your contact centre solution.
                            </p>
                          </div>
                          <div id="coreFour" className="core-col">
                            <i className="image">
                              <img src="assets/images/core-img-04.png" alt="" />
                            </i>
                            <h2 className="title">
                            <b>Driven by You</b>: How We Tailor Services for Success
                    
                            </h2>
                            <p className="section-text ">
                               We do not follow a cookie-cutter approach. We listen to you. We help develop and execute a strategic plan that is aligned with your business goals, current processes, and system capabilities.
                            </p>
                          </div>

                        </div>

                      </div>
                    </div>
                </section>

            <LetConnect darkicon to="/contact-us" title="Let us know how we can assist you" buttonlabel="LET'S CONNECT" />

        </article>

      </Layout>
    )

}

export default WHOWEARE;