import React from 'react';
import Slider from "react-slick";

import { ButtonLink } from "../../components/ui/button";
import { ScrollTotop } from "../../utils/scrollFunctions"

import ashnad from '../../assets/images/proceed/ashnad.jpeg'
import chiristina from '../../assets/images/proceed/chiristina.jpeg'
import ganashyam from '../../assets/images/proceed/ganashyam.jpeg'
import jyothis from '../../assets/images/proceed/jyothis.jpeg'
import mushraf from '../../assets/images/proceed/mushraf.jpeg'
import rasi from '../../assets/images/proceed/rasi.jpeg'
import sinan from '../../assets/images/proceed/sinan.jpeg'
import vishnu from '../../assets/images/proceed/vishnu.jpeg'

var settings = {
	slidesToShow: 1,
  infinite: true,
	autoplay: true,
	autoplaySpeed: 2000,
	speed: 2000,
	cssEase: "linear",
	vertical: true,
	verticalSwiping: false,
	arrows: false,
	pauseOnHover: true,
}

const WordFormCandidate=()=>{
  return(
    <div
    className="item slick-slide slick-cloned"
    data-slick-index={-3}
    id=""
    aria-hidden="true"
    tabIndex={-1}
    style={{ width: 302 }}
  >
    <div className="image">
      <img src="assets/images/learing-user-04.jpg" alt="" />
    </div>
    <div className="info">
      <span>
        “we can help you set the beginning of a successful career in
        technology.”
      </span>
      <b className="name">- Muhanned Rasi</b>
    </div>
  </div>
  )
}

const testimonials=[
  {
      "people-name":"Muhammed Shinan",
      "people-course":"MERN Stack @ Freston ProSEED",
      "people-image" : sinan,
      "people-words":`
      I gained a solid understanding of the MERN Stack in just a
      few months! The instructors were knowledgeable and
      patient, and the hands-on approach really helped me build
      my portfolio. Now, I'm working as a full-time developer
      and loving it! .I'm now a confident developer, Thanks to
      Freston ProSEED
    `
  },
  {
      "people-name":"Ashnad",
      "people-course":"MERN Stack @ Freston ProSEED",
      "people-image" : ashnad,
      "people-words":`
      I couldn't be happier with my experience at Freston
      ProSEED. The instructors were always available for support
      and guidance, and the community of learners made the
      process fun and engaging. I highly recommend ProSEED to
      anyone looking to break into the world of development.
    
      `,
  
  },
  {
      "people-name":"Muhammed Rasi",
      "people-course":"Data Engineering @ Freston ProSEED",
      "people-image" : rasi,
      "people-words":`
      ProSEED's Data Engineering course was a game changer for
      me. The instructors provided real-world examples that
      helped me understand complex concepts, and the hands-on
      approach allowed me to apply my knowledge in practical
      ways. I'm now working in data engineering, and it's all
      thanks to Freston ProSEED!
    
      `
  },
  {
      "people-name":"Mushraf",
      "people-course":"MERN Stack @ Freston ProSEED",
      "people-image" : mushraf,
      "people-words":`
      I came into ProSEED with little coding experience, but the
      MERN Stack course at Freston ProSEED gave me the skills I
      needed to succeed in the industry. The instructors were
      supportive and knowledgeable, and the hands-on learning
      approach made all the difference. I'm now a confident
      developer,
      `
  },
  {
      "people-name":"Ghanashyam",
      "people-course":"MERN Stack @ Freston ProSEED",
      "people-image" : ganashyam,
      "people-words":`
      Freston ProSEED's MERN Stack course transformed my career.
      The instructors were engaging, and the curriculum was
      comprehensive, covering everything I needed to know. I'm
      now working as a developer, and I owe it all to the
      fantastic education I received at ProSEED.
      `
  },
  {
      "people-name":"Vishnu",
      "people-course":"MERN Stack @ Freston ProSEED",
      "people-image" : vishnu,
      "people-words":`
      The MERN Stack course at Freston ProSEED was the perfect
      stepping stone for my career in development. The
      instructors were passionate about teaching and genuinely
      cared about my success. I'm proud to be a part of the
      ProSEED community and look forward to further growth in my
      career.
      `
  },
  {
      "people-name":"Jyothis",
      "people-course":"MERN Stack @ Freston ProSEED",
      "people-image" : jyothis,
      "people-words":`
      MERN Stack "From the start, I knew that Freston ProSEED
      was the right choice for my development education. The
      MERN Stack course was comprehensive and engaging, and the
      instructors provided valuable insights and guidance.
      Thanks to ProSEED, I'm now working as a successful
      developer.
      `
  },
  {
      "people-name":"Christina",
      "people-course":"MERN Stack @ Freston ProSEED",
      "people-image" : chiristina,
      "people-words":`
      I had always been interested in software Testing, but I
      didn't know where to start. Freston ProSEED's Testing
      course provided me with the foundation I needed to excel
      in the industry. The instructors were patient and
      knowledgeable, and the hands-on learning approach made all
      the difference. I can't thank ProSEED enough for helping
      me achieve my goals!
      `
  }
]

const ProceedFreston = () => {

  return (
    <div className='proceed-freston-container' data-theme="dark">

        <div className='cointainer'>
          
          <div className='section-container' >
            <div className="learning-slider-col slider-padding"  >
                          
              <div className="learning-slider one slick-initialized slick-slider slick-vertical">
                <div className="slick-list draggable" >
                  <div
                    className="slick-track"
                  >
                    <Slider {...settings}>
                    {
                      testimonials.map((item,index)=>{
                        return(
                          <div
                          className="item slick-slide slick-cloned"
                          data-slick-index={-3}
                          id=""
                          // aria-hidden="true"
                          tabIndex={-1}
                          key={index}
                          style={{ width: 302 }}
                        >
                          <div className="image">
                            <img  src={`${testimonials[index]['people-image']}`} alt="" />
                          </div>
    
                          <div className="info left-info">
                            <span>
                              {testimonials[index]['people-words']}
                            </span>
                            <b className="name"> - {testimonials[index]['people-name']}</b>
                          </div>
    
                        </div>
                        )
                      })
                    }
                    </Slider>
                  </div>
                </div>
              </div>
    
              <div className="learning-slider two slick-initialized slick-slider slick-vertical">
                <div className="slick-list draggable" >
                <div
                    className="slick-track"
                  >
                    <Slider {...settings}>
                    {
                      testimonials.map((item,index)=>{
                        return(
                          <div
                          className="item slick-slide slick-cloned"
                          data-slick-index={-3}
                          id=""
                          // aria-hidden="true"
                          tabIndex={-1}
                          key={index}
                          style={{ width: 302 }}
                        >
                          <div className="image">
                            <img  src={`${testimonials[index]['people-image']}`} alt="" />
                          </div>
    
                          <div className="info right-info">
                            <span>
                              {testimonials[index]['people-words']}
                            </span>
                            <p className="name"> - {testimonials[index]['people-name']}</p>
                          </div>
    
                        </div>
                        )
                      })
                    }
                    </Slider>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div  className='section-container' >
            
            <div className='section-inner-container' >

            <div
                className="learning-logo"
                alt=""
                  />

              <h2 className="section-title">
                Experience ultimate career readiness with Us
              </h2>

              <p className="section-text">
                Are you looking to take your career to the next level? Look no
                further than Freston Proseed.
              </p>

              <p className="section-text">
                With our program, you'll gain the skills and confidence needed to
                succeed in your career.{" "}
              </p>

              <ButtonLink to="/freston-proceed"  label="More Details" />

            </div>
            
          </div>
          
        </div>

    </div>
  );

}

export default ProceedFreston;