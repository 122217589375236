import React, { useState } from "react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import Layout from "../../components/layout";
import LetConnect from "../../components/common/lets-coonect";
import DispalySection from "../../components/common/service-industry-main-display";

import { FormSubmit } from "../../components/ui/button";
import background from "../../assets/background-images/background_07.png";

// /[abcdefghijklmnopqrstuvwxyz]+/
const LoginSchema = Yup.object().shape({
  name: Yup.string().required("Your name is required!"),
  email: Yup.string()
    .required("Your email is required!")
    .email("You have entered an invalid email address!"),
  phone: Yup.string()
    .required("Your phone number is required!").matches( /^\d+$/ , 'Invalid phone number')
    .min(10, "Minimum 10 digit number required")
    .max(10, "Invalid phone number"), //"You have entered an invalid phone number!
  position: Yup.string().required("Your position is required!"),
  resume: Yup.mixed().required("File is required"),
});

const metatag = {
  title: "Careers",
};

const Carrier = () => {
  const [apiRes, setRes] = useState({
    success: false,
    loading: false,
  });

  return (
    <Layout page="carrier" metatag={metatag}>
      <article>
        <DispalySection
          image={background}
          title={<>Steer your career in the right direction</>}
          subtitle={
            <>
              Build a career that suits your lifestyle,in a company where your
              voice matters. <br />
              Let's build a sustainable world together.
            </>
          }
        />

        {/* <section className='carrier-list-section' data-theme='light' >
                    <div className='container' >
                        <div className='carrier-list-head' >
                            <h4 className='carrier-list-title' >You decide what comes next.<br/> Choose <span> your specialization </span> </h4>
                            <p className='carrier-list-sub-title' >We want to offer our team members personalized opportunities for growth, based on their level of experience. There is always room for development.</p>
                        </div>
                        <div className='carrier-list-container'>

                                <div className='carrier-list-item'>
                                    <div >
                                        <h3 className='carrier-job-title'>Full Stack Developer</h3>
                                        <p className='carrier-job-description'>We are seeking a dedicated full-stack developer to join our team. You will work as part of a team to design and develop high-quality software solutions for web applications using various web technologies. As a full stack developer, you will implement new features and maintain existing code using React, Node.js and other standard tools, your duties will include designing software solutions to meet project requirements, maintaining and refactoring existing code, writing tests and fixing bugs...</p>
                                        <div>India - Calicut <span className='circle-spot'></span> 4+ years</div>
                                    </div>
                                    <div >
                                        <div className='carrier-apply-now' >
                                            <ButtonLink labelcolor="black" target="blank" to="https://frestonanalytics.zohorecruit.in/jobs/Careers/" label="APPLY NOW"/>
                                        </div>
                                    </div>
                                </div>
                                <div className='carrier-list-item'>
                                    <div >
                                        <h3 className='carrier-job-title'> DevOPs Engineer</h3>
                                        <p className='carrier-job-description'>Using full ITIL framework along with internal and external expertise to identify and resolve complex issues, ensuring the best application performance and customer experience. Supporting the development and delivery of platform technology upgrades...</p>
                                        <div>India - Calicut <span className='circle-spot'></span> 4+ years</div>
                                    </div>
                                    <div >
                                        <div className='carrier-apply-now' >
                                            <ButtonLink labelcolor="black" target="blank"  to="https://frestonanalytics.zohorecruit.in/jobs/Careers/" label="APPLY NOW"/>
                                        </div>
                                    </div>
                                </div>

                        </div>
                    </div>
            </section> */}

        <section className="carrier-list-section" data-theme="light">
          { 
          
          apiRes.success ? (
             <div className="container">
             <div className="carrier-list-head">
               <h4 className="carrier-list-title">
               <span> Thank you </span> for connecting us!
               </h4>
               <p className="carrier-list-sub-title">
                   We've received your message, we'll reach out to you immediately
               </p>
             </div>
           </div>
            
          ):(
            <div className="container">
            <div className="carrier-list-head">
              <h4 className="carrier-list-title">
                Discover Your <span>Future </span>{" "}
              </h4>
              <p className="carrier-list-sub-title">
                Are you carazy enough to think you can change the world? Drop
                your CV.
              </p>
            </div>
          </div>
          )
          
          }
        </section>

        <section
          className="carrier-form-section"
          background-color={"white"}
          data-theme={"light"}
          theme={"light"}
        >
          <div className="form-container-row">

            <div className="contact-us-form" >
            {
                apiRes.loading ? <div className="success-message-container">
                <div>
                  <p className="short-message">
       
                      Please wait for sometime ......
                  </p>
                </div>
              </div> :
            <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              position: "",
              resume: null,
            }}
            validationSchema={LoginSchema}
            onSubmit={async (values, actions) => {

              setRes({success:false,loading:true})

              let formData = new FormData();

              formData.append("name", values.name);
              formData.append("email", values.email);
              formData.append("phone", values.phone);
              formData.append("position", values.position);
              formData.append("resume", values.resume);

              const res =await axios.post(
                "/career",
                formData,
                {
                  baseURL:'https://api.happynewyear.freston.io',
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              if(res.status === 201){
                setRes({success:true,loading:false})
                setTimeout(() => {
                  setRes({success:false,loading:false})
                  actions.setSubmitting(false)
              }, 5000)
              }

            }}
          >
            {({values,setFieldValue}) => (
              <Form className="contact-us-form" id="submit-form">
                <Field className="input" type="text" name="name">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors },
                    meta,
                  }) => (
                    <div>
                      <input
                        type="text"
                        className="input"
                        placeholder="Your name"
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="email">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors },
                    meta,
                  }) => (
                    <div>
                      <input
                        type="email"
                        className="input"
                        placeholder="Your email"
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                <Field className="input" type="number" name="phone">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors, },
                    meta,
                  }) => (
                    <div>
                      <input
                        type="text"
                        className="input"
                        placeholder="Your phone"
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="position">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors },
                    meta,
                  }) => (
                    <div>
                      <select
                        className="input"
                        type="text"
                        style={{ cursor: "pointer" }}
                        {...field}
                      >
                        <option className="option" value="">
                          {" "}
                          - Select Position -{" "}
                        </option>
                        <option
                          className="option"
                          value="Java Springboot Developer"
                        >
                          Java Springboot Developer
                        </option>
                        <option
                          className="option"
                          value="Sr. Technical  Project Manager"
                        >
                          Sr. Technical Project Managerr
                        </option>
                        <option
                          className="option"
                          value="Sr. IT Recruiter"
                        >
                          Sr. IT Recruiter
                        </option>
                        <option
                          className="option"
                          value="Sr. Graphic Designer"
                        >
                          Sr. Graphic Designer
                        </option>
                        <option
                          className="option"
                          value="Data Engineer (ELK)"
                        >
                          Data Engineer (ELK)
                        </option>
                        <option
                          className="option"
                          value="Data Engineer (Big Data)"
                        >
                          Data Engineer (Big Data)
                        </option>
                        <option
                          className="option"
                          value="React.js Developer"
                        >
                          React.js Developer
                        </option>
                        <option
                          className="option"
                          value="React Native Developer"
                        >
                          React Native Developer
                        </option>
                        <option
                          className="option"
                          value="MERN stack Developer"
                        >
                          MERN stack Developer
                        </option>
                        <option
                          className="option"
                          value="Software Automation Tester"
                        >
                          Software Automation Tester
                        </option>
                      </select>
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <span className="upload-resume-text">
                  Upload your resume
                </span>

                <Field name="resume">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors },
                    meta,
                    // setField
                  }) => (
                    <div>
                      <input
                        className="file-upload"
                        id="resume"
                        type="file"
                        accept="application/pdf,application/msword"
                        // {...field}
                        value={values.resume?.filename}
                        onChange={(e)=>{
                          console.log(e.target?.files[0])
                          setFieldValue('resume',e.target?.files[0])
                          // setResumeFile(e.target?.files[0])
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                <FormSubmit labelcolor="black" label="Apply Now" />
              </Form>
            )}
    </Formik>
          } 




               {

                // <form ref={form}  className='contact-us-form'  id="submit-form" role="form" >

                //         <input placeholder='Your name' id="form_name" className="input name" value={formik.values.form_name} onChange={formik.handleChange} type="text" name="form_name" required />
                //         <div className='input-error' >{ ( formik.touched.form_name && formik.errors.form_name ) ? formik.errors.form_name : "" }</div>
                //         <input placeholder='Email Address' id="form_email" className="input Email" value={formik.values.form_email} onChange={formik.handleChange} type="email" name="form_email" required/>
                //         <div  className='input-error' >{ ( formik.touched.form_email && formik.errors.form_email ) ? formik.errors.form_email : "" }</div>
                //         <input placeholder='Phone number' id="form_phone" className="input Phone" value={formik.values.form_phone} onChange={formik.handleChange} type="number"  name="form_phone" required />
                //         <div className='input-error' >{  ( formik.touched.form_phone && formik.errors.form_phone ) ? formik.errors.form_phone : "" }</div>

                //         <select  className="select-position" value={formik.values.form_position} onChange={formik.handleChange} type="text" name="form_position" id="form_position">
                //             <option className='option' value="volvo"> - Select Position - </option>
                //             <option className='option' value="saab">DevOp</option>
                //             <option className='option'value="opel">Java Developer</option>
                //             <option  className='option' value="audi">Node Developer</option>
                //         </select>

                //         <div className='input-error' >{  ( formik.touched.form_position && formik.errors.form_position ) ? formik.errors.form_position : "" }</div>
                //         <span className='upload-resume-text'>Upload your resume</span>
                //         <input className='file-upload' id="form_resume" name="form_resume"  value={formik.values.form_position} onChange={()=>formik.handleChange}  type='file' accept='application/pdf,application/msword' required  />
                //         <div className='input-error' >{ ( formik.touched.form_message && formik.errors.form_message ) ? formik.errors.form_message : "" }</div>
                //         <div className='submit-button-container'>
                //            <ButtonLink onClick={()=>formik.handleSubmit()}  labelcolor="black" label="Apply" />
                //         </div>

                // </form>
              }

            </div>

            <div className="carrer-picture">
              <img src="assets/images/display-pic-contact-us-02.png" alt="" />
            </div>
          </div>
        </section>

        <LetConnect
          darkicon
          to="/contact-us"
          title="Let us know how we can assist you"
          buttonlabel="LET'S CONNECT"
        />
      </article>
    </Layout>
  );
};

export default Carrier;
