import { ButtonLink } from '../../components/ui/button';

const ApiGatewaySolutions = () => {

    return (      
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="section-title">
              Enhance your service capabilities with our robust{" "}
              <b>API Gateway Solutions</b>
            </h2>
          </div>
        </div>
        <div className="insight-container">
          <div className='large-display'>
            <div>
              <h5 className="section-head">Insights</h5>
              {/* <div className="insight">
                <p className="section-text ">
                An API gateway works by receiving API requests from a client, aggregating multiple requests, and routing them to the required services.
                </p>
              </div> */}
              <div className="insight">
                <p className="section-text ">
                  In today's fast-paced and interconnected world, modern application development requires a robust and scalable infrastructure to handle the increasing demand for services. 
                </p>
                <ButtonLink small to="/gateway-to-the-future" labelcolor="black" label="Explore" />
              </div>
            </div>
          </div>
          <img className="api-image img-fluid"
              src="assets/images/solution-api.svg"
              alt=""
              title=""
               />
            <div className='mobile-display'>
            <div className='mobile-scroll'>
<>
                      {/* <div className='scroll-card'>
                            <h5 className="section-head">Insights</h5>
                            <div className="insight">
                              <p className="section-text ">
                              In today's fast-paced and interconnected world, modern application development requires a robust and scalable infrastructure to handle the increasing demand for services. 
                              </p>
                              <ButtonLink small   to="/home/api-gateway" labelcolor="black" label="Explore" />
                            </div>
                        </div> */}

                        <div className='scroll-card'>
                            <h5 className="section-head">Insights</h5>
                            <div className="insight">
                              <p className="section-text ">
                              In today's fast-paced and interconnected world, modern application development requires a robust and scalable infrastructure to handle the increasing demand for services. 
                              </p>
                              <ButtonLink small to="/gateway-to-the-future" labelcolor="black" label="Explore" />
                            </div>
                        </div>

</>


            </div>
            </div>
        </div>
      </div>
   );

}
 
export default ApiGatewaySolutions;