

import React from 'react';
import working_progress from '../../assets/images/gif/under-construction.gif'

const WorkingProgress =()=>{
    return(
    <section className='working-progress-section' data-theme="light" background-color="white" >

        <div className='working-progress'>
            <h3>Work in Progress...</h3>
            <img className="img-fluid " style={{maxWidth:"500px",width:"100%"}} src={working_progress} alt="" />
        </div>

    </section>
    )
}

export default  WorkingProgress;