import React,{useEffect} from 'react';
import Header from './header';
import Footer from './footer';
import { gsap } from "gsap";

import ScrollTotop from '../../utils/scrollFunctions'

const Layout =(props)=>{

  useEffect(()=>{

    if(props?.page !== "home"){
      ScrollTotop()
    }

    let elements = gsap.utils.toArray("p")

    elements.forEach((element)=>{
      element.setAttribute("title", "")
    })

    elements = gsap.utils.toArray("section")

    elements.forEach((element)=>{
      element.setAttribute("title", "")
    })


    elements = gsap.utils.toArray("a")

    elements.forEach((element)=>{
      element.setAttribute("title", "")
    })

    elements = gsap.utils.toArray("img")

    elements.forEach((element)=>{
      element.setAttribute("title","")
    })

    elements = gsap.utils.toArray("h1")

    elements.forEach((element)=>{
      element.setAttribute("title","")
    })

    elements = gsap.utils.toArray("h2")

    console.log("h2 element",elements)

    elements.forEach((element)=>{
      element.setAttribute("title","")
    })

    elements = gsap.utils.toArray("h3")

    elements.forEach((element)=>{
      element.setAttribute("title", "")
    })

    elements = gsap.utils.toArray("h4")

    elements.forEach((element)=>{
      element.setAttribute("title", "")
    })

    elements = gsap.utils.toArray("h5")

    elements.forEach((element)=>{
      element.setAttribute("title", "")
    })

    elements = gsap.utils.toArray("span")

    elements.forEach((element)=>{
      element.setAttribute("title", "")
    })

  },[])

  document.title = `Freston Analytics ${props?.metatag?.title === undefined ? "":`| ${props?.metatag?.title}`}`

  let meta = gsap.utils.toArray("meta");

  meta.forEach((meta)=>{


    let name_property = meta.getAttribute('property')||meta.getAttribute('name')

    console.log("name_property",meta.getAttribute('content'),props?.metatag?.hasOwnProperty(`${name_property}`))

    let content = props?.metatag?.hasOwnProperty(`${name_property}`) ? props?.metatag[`${name_property}`] : meta.getAttribute('content')

    if(name_property !== null){
      meta.content = content
    }

  })

    return(
      <React.Fragment>
        <Header page={props.page}  />
        {props.children}
          {/* <main className={`${props.page} show`}>
          </main> */}
        <div className='placeholder' />
        
        <Footer/>
      </React.Fragment>
    );

}

export default Layout