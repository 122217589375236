
import React  from 'react';
import Layout from '../../components/layout';
import WorkingProgress from '../../components/common/working-progress';


const metatag = {
    "title": "Terms of use"
}

const TermsOfUse = ()=>{

    return(
        <Layout page="terms-of-use" metatag={metatag}>
        <article className='terms-of-use'>
           <WorkingProgress ></WorkingProgress>
        </article>
        </Layout>
    )
}

export default TermsOfUse