import React from 'react';

import Layout from '../../components/layout';
import LetConnect from '../home/let-coonect-us'

import LetConnectForm from './let-coonnect-form';

const metatag={
  "title":"Contact Us"
}

const ContactUs = () => {

    return ( 
        <Layout page="contact-us" metatag={metatag} >
            <article>

                <section className='contact-us-section' background-color={"white"} data-theme={"light"} theme={"light"}  >
                  <LetConnectForm/>
                </section>

                <section className='let-connect' data-theme='dark' >
                  <LetConnect page="let-connect" />
                </section>

            </article>
        </Layout>
     );
}
 
export default ContactUs
