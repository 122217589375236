import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/home/index-orginal'
import WhoWeAre from '../pages/who-we-are'
import ContactUs from '../pages/contact-us'
import Careers from '../pages/carrier'
import LifeAtFreston from '../pages/life-at-freston';
// import BlogInnerPage from '../pages/blogs/inner-page'
import OurIndustry from '../pages/our-industry';
import OurServices from '../pages/our-services';
import OurProduct from '../pages/our-product'
import OurTeams from '../pages/our-team'
import Blogs from '../pages/blogs'
import FrestonProceed from '../pages/proceed'
import GlobalManagedService from '../pages/home/big-data-insight/global-managed-service.jsx'
import APIGatewayToFuture from '../pages/home/api-gateway/gateway-to-the-future'
import UniqueCapabilitiesApiGateway from '../pages/home/api-gateway/unique-capabilities-of-api-gateway.jsx'
import OperatorConnect from '../pages/home/big-data-insight/operator-connect.jsx'

import UnifiedCommunication from '../pages/home/unified-communication/index.jsx'
import AiOperationSolution from '../pages/home/ai-operation-solution/index.jsx'
// import BigData from '../pages/home/big-data-insight/index.jsx'
import FiveG3GppSpecification from '../pages/home/5g-3gpp-specification/index.jsx'
import SaferCircle from '../pages/our-product/safer-circle'
import ProcessManager from '../pages/our-product/process-manager'
import KallerCloud from '../pages/our-product/kaller-cloud'
import Gpdr from '../pages/gpdr'
import TermsOfUse from '../pages/terms-of-use'
import CookiePolicy from '../pages/cookie-policy'

const Router = () => {
  
  return (

      <Routes>

        <Route
                path={'/'}
                element={<Home />} />
        <Route
            path={'/operator-connect'}
            element={<OperatorConnect />}
            />

        <Route
            path={'/global-managed-service'}
            element={<GlobalManagedService />}
            />


        <Route
            path={'/gateway-to-the-future'}
            element={<APIGatewayToFuture />}
            />

        <Route
            path={'/unique-capabilities-of-api-gateway'}
            element={<UniqueCapabilitiesApiGateway />}
        />

        <Route
            path={'/ai-operation'}
            element={<APIGatewayToFuture />}
          />

        {/* <Route
            path={'/unified-communication-platform'}
            element={<UnifiedCommunication />}
          /> */}

        {/* <Route
            path={'/ai-operation-solution'}
            element={<AiOperationSolution />}
          /> */}


        {/* <Route
            path={'/5g-3gpp-specification'}
            element={<FiveG3GppSpecification />}
          />   */}

        <Route
          path={'/who-we-are'}
          element={<WhoWeAre />}
        />

        <Route
          path={'/contact-us'}
          element={<ContactUs />}
        />

        <Route
          path={'/careers'}
          element={<Careers />}
        />

        <Route
          path={'/life-at-freston'}
          element={<LifeAtFreston />}
        />

        {/* <Route
          path={'blogs/:blog_title'}
          element={<BlogInnerPage />}
        /> */}

        <Route
          path={'/our-industry'}
          element={<OurIndustry />}
        />

        <Route
          path={'/our-services'}
          element={<OurServices />}
        />


       <Route
          path={'/our-teams'}
          element={<OurTeams />}
        />


        <Route path={'/our-products'}  element={<OurProduct />} />
        {/* <Route path={'/our-product-safer-circle'}  element={<SaferCircle />} /> */}
        <Route path={'/our-product-process-manager'}  element={<ProcessManager />} />
        <Route path={'/our-product-kaller-cloud'}  element={<KallerCloud />} />

        <Route
          path={'/blogs'}
          element={<Blogs />}
        />

        <Route
          path={'/gpdr'}
          element={<Gpdr />}
        />

        <Route
          path={'/freston-proceed'}
          element={<FrestonProceed />}
        />

        <Route
          path={'/terms-of-use'}
          element={<TermsOfUse />}
        />

        <Route
          path={'/cookie-policy'}
          element={<CookiePolicy />}
        />

      </Routes>

  );
  
};

export default Router;