import React,{ useRef,useState } from 'react';
import { ButtonLink } from '../../components/ui/button';

import { useFormik } from "formik";
import * as Yup from "yup";

// import emailjs from '@emailjs/browser';

const LoginSchema = Yup.object().shape({
    form_name: Yup.string().required('Your name is required!'),
    form_email: Yup.string().required('Your email is required!').email("You have entered an invalid email address!"),
    form_phone: Yup.string().required('Your phone number is required!').min(10,"Minimum 10 digit number required").max(10,"You have entered an invalid phone number"),//"You have entered an invalid phone number!
    form_message: Yup.string().required('Your requirements is required!'),
});

const LetConnectForm = () => {

    const form = useRef();
    const [sendmailRes,setSendmailRes]=useState({status:false,message:'',error:false});

    const HandleSubmit = async (values) => {

        // const params = {
        //     form_name: values.form_name,
        //     form_email: values.form_email,
        //     form_phone: values.form_phone,
        //     form_message: values.form_message,
        // };

        setSendmailRes({status:true,message:'Thank you for contacting us. We will get back to you soon!!'})

        //   emailjs.sendForm('service_8q3ls1s', 'template_8elie92', form.current, 'gEyOSKi9puZ-7Q0LY')
        //     .then((result) => {
        //       setSendmailRes({status:true,message:'Thank you for contacting us. We will get back to you soon!!'})
        //     }, (error) => {
        //         console.log("form.error",error)
        //       setSendmailRes({status:false,message:'Failed... please try again'})
        //     });
    
      };

    const formik = useFormik({
        initialValues: {
            form_name: "",
            form_email: "",
            form_phone: "",
            form_message: "",
        },
        validationSchema: LoginSchema,
        onSubmit: HandleSubmit,
      });

    return ( 
        <div className='form-container' >
            <div className='container' >

                <div className='form-container-row'>
                    <div className='contact-us-form'>

                        {
                            sendmailRes.status ? 

                            <div className='success-message-container' >
                                <div>
                                    <p className='success-message' > <span> Thank you </span> for connecting us!  </p>
                                    <p className='short-message'> We've received your message, we'll reach out to you immediately </p>
                                    <div style={{marginLeft:'0px',paddingTop:'20px'}}>
                                    <ButtonLink to="/home" labelcolor="black" label="BACK TO HOME" />
                                    </div>
                                </div>
                            </div>

                            :

                            <div>

                                <div className='contact-us-form-head'>
                                    <h2> <span style={{color:'#F3684A'}}>Let's Connect</span> and Grow</h2>
                                    <h4>Please fill out the form below to get in touch with us.</h4>
                                </div>

                                <form ref={form}  className='contact-us-form'  id="submit-form" role="form" method="POST">

                                    <input placeholder='Your name' id="form_name" className="input name" value={formik.values.form_name} onChange={formik.handleChange} type="text" name="form_name" required />
                                    <div className='input-error' >{ ( formik.touched.form_name && formik.errors.form_name ) ? formik.errors.form_name : "" }</div>
                                    <input placeholder='Email Address' id="form_email" className="input Email" value={formik.values.form_email} onChange={formik.handleChange} type="email" name="form_email" required/>
                                    <div  className='input-error' >{ ( formik.touched.form_email && formik.errors.form_email ) ? formik.errors.form_email : "" }</div>
                                    <input placeholder='Phone number' id="form_phone" className="input Phone" value={formik.values.form_phone} onChange={formik.handleChange} type="number"  name="form_phone" required />
                                    <div className='input-error' >{  ( formik.touched.form_phone && formik.errors.form_phone ) ? formik.errors.form_phone : "" }</div>
                                    <input placeholder='Tell us about your requirements' id="form_message" className="form message" name="form_message"   value={formik.values.form_message} onChange={formik.handleChange}  type="text"  required  />
                                    <div className='input-error' >{ ( formik.touched.form_message && formik.errors.form_message ) ? formik.errors.form_message : "" }</div>
                                    
                                </form> 

                            </div>

                        }

                    </div>

                    <div>
                        <img className='contact-us-right-display-pic' src='assets/images/display-pic-contact-us-02.png'  alt="" />
                    </div>
                </div>
                {
                    ! sendmailRes.status && (
                    <div className='contact-us-button' >

                                <div style={{width:'fit-content'}} onClick={ ()=>{ formik.handleSubmit() } } >

                                    <div className="botton-container">
                                        <a className="button" >
                                            <div className="circle" />
                                            <input style={{width:"235px",height:"72px",padding:'2px',background:'transparent',position:"absolute",border:'none',color:'transparent'}} type="submit" defaultValue="" />
                                            <div className="button-label" style={{ color: "black" }}>
                                            Connect
                                            </div>
                                            <img
                                            src="assets/images/arrow.svg"
                                            style={{
                                                zIndex: 20,
                                                transform: "translateX(10px)",
                                                paddingLeft: 20,
                                                filter: "brightness(10%) contrast(90%)"
                                            }}
                                            alt=""
                                            />
                                        </a>
                                    </div>

                                </div>

                    </div>
                    )
                }
                
            </div>
        </div>
     );

}
 
export default LetConnectForm;