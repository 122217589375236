import React  from 'react'
import Layout from '../../../components/layout'
import LetConnect from '../../../components/common/lets-coonect'

import DispalySection from '../../../components/common/service-industry-main-display'

import improve_security from "../../../assets/png-icon/improve-security.png"
import enhances_scalability from "../../../assets/png-icon/enhances-scalability.png"
import scalability_flexibility from "../../../assets/png-icon/scalability-flexibility.png"

import gradient_circle_1 from "../../../assets/png-icon/gradient-circle-1.png"
import gradient_circle_2 from "../../../assets/png-icon/gradient-circle-2.png"

import case_study_2 from "../../../assets/images/api-gateway/api-gateway-02.png"
import case_study_3 from "../../../assets/images/api-gateway/api-gateway-03.png"
import case_study_4 from "../../../assets/images/api-gateway/api-gateway-01.png"
import case_study_5 from "../../../assets/images/api-gateway/api-gateway-04.png"

import background_image_2 from "../../../assets/images/api-gateway/banner.jpg"

import client from '../../../assets/png-icon/client.png'
import gateway from '../../../assets/png-icon/gate-way.png'
import backend from '../../../assets/png-icon/backend.png'
import swingarrow from '../../../assets/png-icon/swing-arrow.png'
import user_handup from '../../../assets/png-icon/user-two-hand-up.png'

import './api-gateway.scss'

const metatag = {
    "title": "Case Study"
}

const ApiGateWay = () => {

    return (

        <Layout page="api-gateway" metatag={metatag}>

            <article className='api-gateway'>
                
                <DispalySection image={background_image_2}  title={<div> Gateway to the Future: <br />  Exploring the Power of API <br /> Gateway </div>} subtitle={<>From strategy to execution, we help you achieve success</>} />

                <section className='introduction-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-content' >

                            <p className='label' >INTRODUCTION</p>
                            <p className='title'  > Application Programming Interfaces (<span > API </span> )</p>

                            {/* <p className='title'  >What is <span > API Gateway ? </span> </p> */}

                            <div className='container-image' >
                                <div className='image-container' >
                                    <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                    <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                    <img className='display-image img-fluid' src={case_study_2} alt="" />
                                </div>
                            </div>

                            <p className='description' > APIs (Application Programming Interfaces) play an integral role in enabling the modern-day apps and software programs to communicate with each other.
As businesses add more functionalities to their existing software and add on more applications, APIs allow different software components to share data and interact with each other.
                            </p>

                            {/* <p className='description' > APIs play an integral role in enabling the modern-day apps and software programs to communicate with each other.
                            </p>

                            <p className='description-second' >
                                As businesses add more functionalities to their existing software and add on more applications, APIs allow different software components to share data and interact with each other.  
                            </p> */}

                        </div>

                        <div className='container-image' >
                            <div className='image-container' >
                            <img className='circle-decoration' src={gradient_circle_2}  alt="" />
                            <img className='circle-decoration' src={gradient_circle_1}  alt="" />
                            <img className='display-image' src={case_study_2} alt="" />
                            </div>
                        </div>

                    </div>

                </section>

                <section className='features-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <img className='circle-decoration' src={gradient_circle_2}  alt="" />
                            <img className='display-image' src={case_study_3} alt="" />
                        </div>

                        <div className='container-content'>
                            <p className='label' >FEATURES</p>
                            <p className='title' >
                                <span style={{ color: '#3A314C', fontWeight: '500',lineHeight:'40px' }}> Why API Gateway ? </span> </p>
                                An API gateway is an intermediary software that takes an application user's request, routes it to one or more backend services to gather the appropriate data and deliver it back to the user.
                                <p className='title' > The Key Benefits of API Gateways </p>
                        </div>

                        <div className='card-container' >

                            <div className='card' >
                                <img className='card-icon' src={improve_security}  alt=""  />
                                <p className='title' >Reduces time to market</p>
                                Can accelerate your digital transformation by allowing faster and easier integration of new technology.
                            </div>
                            <div  className='card' >
                                <img className='card-icon' src={enhances_scalability}  alt=""  />
                                <p className='title' >Enhances business value</p>
                                Can improve customer experience and attract partnerships with complementary services.
                            </div>
                            <div className='card' >
                                <img className='card-icon' src={scalability_flexibility}  alt=""  />
                                <p className='title' >Extra layers of security</p>
                                Can provide threat protection and other security for the application.
                            </div>

                        </div>

                    </div>

                </section>

                <section className='api-gateway-section' data-theme={'light'} >

                    <div className='section-container'>

                    <div className='container-head' >
                        <p className='title' > How Does an <span > API Gateway</span>  Work ? </p>
                        <p className='description' > An API Gateway acts as a reverse proxy that sits in front of your APIs and manages traffic between clients and backend services. When a client makes a request to an API, the request is first sent to the API Gateway, which then routes the request to the appropriate backend service. The response from the backend service is then returned to the client through the API Gateway. </p>
                    </div>

                    <div className='container-body' >

                        <div className='card' >
                            <img className='card-icon' src={client}  alt="" />
                            <p className='title' > Client </p>
                            <p className='description' > When a client makes a request to an API, the request is first sent to the API Gateway,</p>
                        </div>

                        <div className='arrow-container' >
                            <img className='arrow-image' src={swingarrow} alt=""/>
                        </div>

                        <div className='card' >
                            <img className='card-icon' src={gateway}  alt="" />
                            <p className='title' > API Gateway </p>
                            <p className='description' > The request sent to the API Gateway then routes the request to the appropriate backend service.</p>
                        </div>

                        <div className='arrow-container'  >
                            <img className='arrow-image' src={swingarrow}  alt=""/>
                        </div>

                        <div className='card'  >
                            <img className='card-icon' src={backend}   alt=""/>
                            <p className='title' > Backend </p>
                            <p className='description' > The response from the backend service is then returned to the client through the API Gateway. </p>
                        </div>

                    </div>

                    <p className='container-footer' > API Gateways also provide additional functionality such as authentication, rate limiting, caching, and protocol translation. This allows developers to focus on building their APIs without having to worry about these concerns. The API Gateway also provides a single-entry point for all API requests, making it easier to manage and monitor API traffic. </p>

                    </div>

                </section>

                <section className='concusion-section' data-theme={'light'} >

                    <div className='main-contrainer'>

                        <div className='section-container' >

                            <div  className='container-image' >
                            <div className='image-container' >
                            <img className='circle-decoration' src={gradient_circle_2}  alt="" />
                                <img className='display-image' src={case_study_4} alt=""  />

                                <div className='inner-card' >
                                    <img className='card-icon' src={user_handup}  alt=""  />
                                    <p className='title' >API Gateway</p>
                                    <p className='description'> When a client makes a request to an API, the request is first sent to the API Gateway,</p>
                                </div>
                            </div>                           
                            </div>

                            <div className='container-content' >
                                    <p className='title'> How does API Gateway differ from <span>API Management</span>? </p>
                                    <p  className='description'>  The request sent to the API Gateway is then routes the request to the appropriate backend service. </p>

                                    <div className="mobile-card-container">
                                        <img  className='background-image' src={case_study_4}  alt="" />
                                        <div className='inner-card' >
                                                <img  className='card-icon' src={user_handup}  alt="" />
                                                <p className='title' > API Gateway </p>
                                            <p className='description'> The response from the backend service is then returned to the client through the API Gateway. </p>  
                                        </div>
                                    </div>
                            </div>

                        </div>

                        <div className='section-container' >

                            <div className='container-content' >
                                <p className='title'> How does API Gateway differ from <span>API Management</span> ? </p>
                                {/* <p className='title' >API Gateway</p> */}
                                <p className='description'> API Management involves a broader set of activities such as monitoring, analytics, and developer engagement. </p>
                                <p className='description' > While an API Gateway acts as a front door to an API Management platform, providing security and routing capabilities, an API Management platform provides a centralised location for managing APIs across different environments and platforms. </p>
                                <p className='description' > With the Internet of Things (IoT) introducing digitalisation to all kinds of products and services, APIs can allow you to expand into markets you might have never considered before.
By implementing best practices for API Gateway implementation, we ensure that our applications are optimised for performance and reliability. </p>
                            
                                <div className="mobile-card-container" >
                                    <img  className='background-image' src={case_study_5}  alt="" />
                                    <div className='inner-card' >
                                        <img  className='card-icon' src={user_handup}  alt="" />
                                        <p className='title' >API Management</p>
                                    <p className='description'>API Management involves a broader set of activities such as monitoring, analytics, and developer engagement.</p>  
                                    </div>
                                </div>

                            </div>

                            <div className='container-image'>

                                <div  className='image-container' >

                                    <img className='circle-decoration' src={gradient_circle_2}  alt="" />
                                    <img className='display-image' src={case_study_5} alt=""  />
                                
                                    <div className='inner-card' >
                                        <img  className='card-icon' src={user_handup}  alt="" />
                                        <p className='title' >API Management</p>
                                        <p className='description'>API Management involves a broader set of activities such as monitoring, analytics, and developer engagement.</p>  
                                    </div>
                                
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <LetConnect to="/contact-us" darkicon title={<>Looking for an API Gateway Solution to help grow your business? Contact Us </>} buttonlabel="LET'S CONNECT" />

            </article>

        </Layout>

    )

}

export default ApiGateWay;