import React from 'react';
import Layout from '../../components/layout';
import Card from '../../components/ui/card';
import LetConnect from '../../components/common/lets-coonect';
import DispalySection from '../../components/common/service-industry-main-display'

import gradient_circle_1 from "../../assets/png-icon/gradient-circle-1.png"
import gradient_circle_2 from "../../assets/png-icon/gradient-circle-2.png"

import industry_1 from "../../assets/images/industry/telecom.png"
import industry_2 from "../../assets/images/industry/healthcare.png"
import industry_3 from "../../assets/images/industry/public-safety.png"

import background_image_3 from "../../assets/background-images/background_03.png"
import background from "../../assets/background-images/background_10.png"

import tower_communication from '../../assets/png-icon/tower-communication.png'
import hert_beats from '../../assets/png-icon/heart-beat.png'



const metatag={
    "title":"Our Products"
}


const OurIndustry = () => {

    return(
        <Layout page="our-industry"  metatag={metatag}>

            <article className='our-industry' >

              <DispalySection image={background} title={<>Leading the Way in Diverse Industries:<br /> Our Commitment to Innovation and Excellence</>} subtitle={<>From strategy to execution, we help you achieve success</>}/>
               
                <section className='second-section' style={{paddingTop:'100px'}} data-theme={'light'} >

                <div className='section-container' >

                    <div className='container-image' >
                        <div className='image-container' >
                            <img alt="" className='circle-decoration' src={gradient_circle_2}  />
                            <img alt="" className='circle-decoration' src={gradient_circle_1}  />
                            <img alt="" className='display-image' src={industry_1}  />
                            <div className='inner-card' >
                                <img alt="" className='card-icon' src={tower_communication}  />
                                <div className='title'>OUR SOLUTIONS</div>
                                <div className='bullet-point-container'>

                                <div className='bullet-item' >
                                    <div className='icon-container'>
                                        <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                    </div>
                                    <div className='bullet-text' > Unified Communication & Service Integration </div>
                                </div>

                                <div className='bullet-item' >
                                    <div className='icon-container'>
                                        <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                    </div>
                                    <div className='bullet-text' > 5G 3GPP Specification </div>
                                </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container-content' >

                        <div className='wrapped-content'>

                            <p className='label' >TELECOM</p>
                            <p className='title'  > Leading the Way <span className='bold'> in Telecom-Based </span>  <span className='color' > Services and Solutions </span>  </p>

                            <p className='description' > We embark on a journey to redefine the way we connect and communicate. In an age where communication is at the heart of progress, we take pride in presenting a comprehensive range of services and products that not only elevate connectivity but also empower businesses and individuals to thrive in a hyper-connected world.</p>
{/* 
                            <div className='bullet-point-container'>
                                <div className='bullet-item' >
                                    <div className='icon-container'>
                                        <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                    </div>
                                    <div className='bullet-text' > Unified Communication & Service Integration </div>
                                </div>
                                <div className='bullet-item' >
                                    <div className='icon-container'>
                                        <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                    </div>
                                    <div className='bullet-text' > 5G 3GPP Specification </div>
                                </div>
                            </div> */}

                        </div>



                        <div className="mobile-card-container">
                            <img alt="" className='background-image' src={background_image_3} />
                            <div className='inner-card' >
                                <img alt="" className='card-icon' src={tower_communication} />
                                <div className='title'>OUR SOLUTIONS</div>
                                <div className='bullet-point-container'>

                                <div className='bullet-item' >
                                    <div className='icon-container'>
                                        <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                    </div>
                                    <div className='bullet-text' > Unified Communication & Service Integration</div>
                                </div>

                                <div className='bullet-item' >
                                    <div className='icon-container'>
                                        <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                    </div>
                                    <div className='bullet-text' > 5G 3GPP Specification </div>
                                </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                </section>

                <section className='first-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-content' >

                            <div className='wrapped-content'>
                                <p className='label' >HEALTH CARE</p>
                                <p className='title'  > Empowering Healthcare: <span className='bold'> How Technology is </span>  <span className='color' > Reshaping the Industry  </span> </p>


                                <p className='description' > We are passionately dedicated to leveraging innovation and technology to transform healthcare into a more accessible, efficient, and patient-centered domain. Our comprehensive suite of services and products has been meticulously crafted to cater to the needs of medical professionals, empower individuals to take control of their health, and elevate the overall quality of healthcare services.
                                </p>

                                {/* <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' >IT Strategy and Planning</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' >Infrastructure Design and Implementation</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' >Security and Risk Management</div>
                                    </div>
                                </div> */}

                            </div>

                            <div className="mobile-card-container" >

                                <img alt="" className='background-image' src={industry_2} />
                                <div className='inner-card' >
                                    <img alt="" className='card-icon' src={hert_beats} />
                                    <div className='title'>HOSPITAL MANAGEMENT</div>
                                    <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Patient Registration and Management</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Appointment Scheduling</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Billing and Invoicing</div>
                                    </div>

                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Electronic Health Records (EHR)</div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='container-image' >

                            <div className='image-container' >

                                <img alt="" className='circle-decoration' src={gradient_circle_2}  />
                                <img alt="" className='circle-decoration' src={gradient_circle_1}  />
                                <img alt="" className='display-image' src={industry_2}  />

                                <div className='inner-card' >
                                    <img alt="" className='card-icon' src={hert_beats}  />
                                    <div className='title'>HOSPITAL MANAGEMENT</div>
                                    <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Patient Registration and Management</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Appointment Scheduling</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Billing and Invoicing</div>
                                    </div>

                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' > Electronic Health Records (EHR)</div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </section>

                <section className='second-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <div className='image-container' >
                                <img alt="" className='circle-decoration' src={gradient_circle_2}  />
                                <img alt="" className='circle-decoration' src={gradient_circle_1}  />
                                <img alt="" className='display-image' src={industry_3}  />
                                <div className='inner-card' >
                                    <img alt="" className='card-icon' src={tower_communication}  />
                                    <div className='title'> SAFER CIRCLE </div>
                                    <div className='bullet-point-container'>
                                        <div className='bullet-item' >
                                            <div className='icon-container'>
                                                <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                            </div>
                                            <div className='bullet-text' >Citizens to report incidents or suspicious behaviour to the agents</div>
                                        </div>
                                        <div className='bullet-item' >
                                            <div className='icon-container'>
                                                <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                            </div>
                                            <div className='bullet-text' >Respond to and prevent crime by providing real-time information</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='container-content' >

                            <div className='wrapped-content'>
                                <p className='label' >PUBLIC SAFETY</p>
                                <p className='title'  > Ensuring a Safer Future: <span className='bold'> Our Commitment </span>  to Public Safety <span className='color' > Public Safety   </span>  </p>

                                <p className='description' > Public Safety Industry is a realm where innovation converges with responsibility to create safer and more secure communities. We are steadfast in our commitment to providing law enforcement agencies, emergency responders, and organizations dedicated to public safety with cutting-edge services and products designed to enhance their capabilities and contribute to a safer society.</p>

                                {/* <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' >IT Strategy and Planning</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' >Infrastructure Design and Implementation</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                        </div>
                                        <div className='bullet-text' >Security and Risk Management</div>
                                    </div>
                                </div> */}

                            </div>

                            <div className="mobile-card-container">
                                <img alt="" className='background-image' src={industry_3} />
                                <div className='inner-card' >
                                    <img alt="" className='card-icon' src={tower_communication} />
                                    <div className='title'>SAFER CIRCLE</div>
                                    <div className='bullet-point-container'>
                                        <div className='bullet-item' >
                                            <div className='icon-container'>
                                                <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                            </div>
                                            <div className='bullet-text' >Citizens to report incidents or suspicious behaviour to the agents</div>
                                        </div>
                                        <div className='bullet-item' >
                                            <div className='icon-container'>
                                                <img alt="" className='buttet-icon' src='assets/images/yes-tick-icon.png'  />
                                            </div>
                                            <div className='bullet-text' >Respond to and prevent crime by providing real-time information</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <LetConnect darkicon to="/contact-us" title="Okay enough about us, Lets talk about you" buttonlabel="LET'S CONNECT"/>
                
            </article>

        </Layout>
    )
}
 
export default OurIndustry;